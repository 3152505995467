import { store } from "@/store";
import { FeatureKnown } from "@/store/modules/features";
import {
  AllowedEventTypes,
  EventType,
  SystemEventTypes,
  VisibleEventTypes,
} from "../event";
import EphemeralParser from "../parsers/ephemerals";
import EventParser from "../parsers/events";
import { ParsingWay } from "../parsers/types";
import { IJoinedRoom, IRoomEvent, IStateEvent } from "../types";
import ChatRoomEvent, { EventReceiptModes } from "./events";
import { ChatRoomMemberCollection } from "./members";
import { ChatRoomReactionsCollection } from "./reactions";

export interface ChatRoomCollection {
  [roomId: string]: ChatRoom;
}

export function simplifyRoomId(roomId: string): string {
  return roomId.split(":")[0] || "";
}

// function timelineViewedStatus(
//   event: IRoomEvent,
//   result: ScanRoomDataResult,
//   alreadyFound: Array<string>,
//   debug = false
// ): void {
//   if (!AllowedEventTypes.includes(event.type as EventType)) {
//     return;
//   }

//   const feature = store.getters["features/enabled"](
//     FeatureKnown.oneToOneIsReadBy
//   );
//   if (feature) {
//     const currentUsers = Object.keys(result.members);
//     const listOfViewedByUsers = Object.keys(result.members).filter(
//       (k) => !alreadyFound.includes(k)
//     );
//     event.computed = {
//       viewedBy: listOfViewedByUsers,
//       viewedByAll: listOfViewedByUsers.length === currentUsers.length,
//     };
//     if (
//       result.receipt &&
//       Object.keys(result.receipt).includes(event.event_id)
//     ) {
//       alreadyFound = [...alreadyFound, ...result.receipt[event.event_id]];
//     }
//   }
//   result.timeline.push(event);
// }

export default class ChatRoom {
  readonly id: string;
  readonly fullId: string;
  tribeId: string;
  anonymous: boolean;
  members: ChatRoomMemberCollection;
  timeline: ChatRoomEvent[];
  previousTimelineBatch?: string;
  reactions: ChatRoomReactionsCollection;
  redaction: Array<string>;
  readStatus: Record<string, string[]>;
  unreadNotification: number;
  creationTime?: Date;
  lastEvent?: ChatRoomEvent;
  olderEventTime?: Date;

  constructor(id: string, data: IJoinedRoom, public debug = false) {
    // if (this.debug) console.log("[ChatRoom]", `construct room ${id}`, data);
    this.id = simplifyRoomId(id);
    this.fullId = id;
    this.tribeId = "unknown";
    this.anonymous = false;
    this.members = {};
    this.readStatus = {};
    this.reactions = {};
    this.redaction = [];
    this.creationTime = new Date();

    if (data.timeline.limited === true) {
      this.previousTimelineBatch = data.timeline.prev_batch;
    }

    const featRead = store.getters["features/enabled"](
      FeatureKnown.oneToOneIsReadBy
    );
    if (
      featRead &&
      data.ephemeral &&
      data.ephemeral.events &&
      data.ephemeral.events.length > 0
    ) {
      const ephemeralParser = new EphemeralParser(this);
      data.ephemeral.events.forEach((evt) => ephemeralParser.parse(evt));
    }

    const eventParser = new EventParser(this, debug);
    data.state.events
      .filter((event) => SystemEventTypes.includes(event.type as EventType))
      .forEach((event) => eventParser.parse(event));

    const timeline = data.timeline.events.filter((event) =>
      AllowedEventTypes.includes(event.type as EventType)
    );
    // const userFound: Array<string> = [];
    timeline.forEach((event) => {
      eventParser.parse(event);
      if (featRead) {
        // timelineViewedStatus(event, result, userFound);
      }
    });

    this.timeline = timeline
      .filter((event) => VisibleEventTypes.includes(event.type as EventType))
      .reverse()
      .map((event) => {
        if (event.room_id === undefined) {
          event.room_id = this.fullId;
        }
        return new ChatRoomEvent(event, this.id, this.members[event.sender]);
      });

    this.unreadNotification = data.unread_notifications.notification_count || 0;

    if (data.timeline.events.length > 0) {
      const excludeSystemTimeline = data.timeline.events.filter(
        (evt) => !SystemEventTypes.includes(evt.type as EventType)
      );
      if (excludeSystemTimeline.length > 0) {
        const lastEvent =
          excludeSystemTimeline[excludeSystemTimeline.length - 1];
        this.lastEvent = new ChatRoomEvent(
          lastEvent,
          this.fullId,
          this.members[lastEvent.sender]
        );
      }

      this.olderEventTime = this.timeline[this.timeline.length - 1]?.date;
    }
    // if (debug)
    //   console.log("[ChatRoom] - Constructor end", this, data.timeline.events);
  }

  update(data: Partial<ChatRoom>): void {
    // const featRead = useStore().getters["features/enabled"](
    //   FeatureKnown.oneToOneIsReadBy
    // );
    if (this.debug) console.log("[ChatRoom]", `Updating ${this.id}`, data);
    const featReact = store.getters["features/enabled"](
      FeatureKnown.oneToOneReactions
    );
    if (data.members && Object.keys(data.members).length > 0) {
      Object.keys(data.members).forEach((id) => {
        if (data.members === undefined) {
          return;
        }

        const member = this.members[id]
          ? { ...this.members[id] }
          : data.members[id];
        if (member) {
          member.avatarURL = data.members[id].avatarURL || member.avatarURL;
          member.displayName =
            data.members[id].displayName || member.displayName;
          member.leaved = data.members[id].leaved;
        }
        this.members[id] = member;
      });
    }
    // if (
    //   featRead &&
    //   data.readStatus &&
    //   Object.keys(data.readStatus).length > 0
    // ) {
    //   const evtIds = Object.keys(data.readStatus);
    //   evtIds.forEach((evtId) => {
    //     if (data.readStatus) {
    //       data.readStatus[evtId].forEach((usrId) => {
    //         this.cleanUserReadById(usrId);
    //         if (this.readStatus[evtId] === undefined) {
    //           this.readStatus[evtId] = [];
    //         }
    //         this.readStatus[evtId].push(usrId);
    //       });
    //     }
    //   });
    // }

    if (featReact && data.reactions) {
      if (this.reactions) {
        const existingIds = Object.keys(this.reactions);
        Object.keys(data.reactions).forEach((eventId) => {
          if (data.reactions) {
            if (!existingIds.includes(eventId)) {
              this.reactions[eventId] = data.reactions[eventId];
              return;
            }
            this.reactions[eventId].merge(data.reactions[eventId].iterators);
          }
        });
      } else {
        this.reactions = data.reactions;
      }
    }

    if (data.timeline) {
      const incomingTimeline = data.timeline.map((event) => {
        event.sender = this.members[event.event.sender];
        return event;
      });

      this.timeline = [...incomingTimeline, ...this.timeline];
      // const foundUsers: Array<string> = [];
      // const res: ScanRoomDataResult = {
      //   members: this.members,
      //   timeline: this.timeline.map((evt) => evt.event),
      // };
      // this.timeline.forEach((evt) => {
      //   timelineViewedStatus(evt.event, res, foundUsers);
      // });
      // this.timeline = res.timeline.map((event) =>
      //   new ChatRoomEvent(
      //     event,
      //     this.members[event.sender],
      //   )
      // );
    }
    if (data.lastEvent) {
      this.lastEvent = data.lastEvent;
    }
    this.unreadNotification =
      data.unreadNotification || this.unreadNotification;
    if (this.debug) console.log("[ChatRoom] - Updated ", this, data);
  }

  prependEvents(
    events: Array<IRoomEvent | IStateEvent>,
    mode = EventReceiptModes.Matrix
  ): void {
    if (this.debug) console.log("[ChatRoom] - PrependEvents ", events, mode);
    const timeline = events.filter((event) =>
      AllowedEventTypes.includes(event.type as EventType)
    );

    if (events.length === 0 || timeline.length === 0) {
      return;
    }
    const eventParser = new EventParser(this);
    timeline.forEach((event) => eventParser.parse(event, ParsingWay.Backward));

    this.timeline.push(
      ...timeline
        .filter((event) => VisibleEventTypes.includes(event.type as EventType))
        .map((event) => {
          if (event.room_id === undefined) {
            event.room_id = this.fullId;
          }
          return new ChatRoomEvent(
            event,
            this.id,
            this.members[event.sender],
            mode
          );
        })
    );

    this.olderEventTime = new Date(this.timeline[this.timeline.length - 1].ts);

    if (this.lastEvent === undefined) {
      const excludeSystemTimeline = events.filter(
        (evt) => !SystemEventTypes.includes(evt.type as EventType)
      );
      let event = excludeSystemTimeline[0];
      if (event.event_id === undefined) {
        event = event.properties as IRoomEvent;
      }
      this.lastEvent = new ChatRoomEvent(
        event,
        this.fullId,
        this.members[event.sender],
        mode
      );
    }
    if (this.debug) console.log("[ChatRoom] - Prepended ", this, events, mode);
  }

  get isClosed(): boolean {
    if (this.members === undefined) {
      return true;
    }

    return (
      Object.values(this.members).filter((member) => !member.leaved).length < 2
    );
  }

  get lastEventDate(): Date | undefined {
    return this.lastEvent ? this.lastEvent.date : this.creationTime;
  }

  get cleanTimeline(): ChatRoomEvent[] {
    return this.timeline.filter((evt) => {
      const AllowedTypes = AllowedEventTypes;
      return AllowedTypes.includes(evt.type);
    });
  }

  private cleanUserReadById(id: string): void {
    const evtIds = Object.keys(this.readStatus);
    evtIds.forEach((evtId) => {
      const pos = this.readStatus[evtId].indexOf(id);
      if (pos >= 0) {
        delete this.readStatus[evtId][pos];
      }
      if (this.readStatus[evtId].length === 0) {
        delete this.readStatus[evtId];
      }
    });
  }
}
