
import { mixins, Options } from "vue-class-component";
import { Navbar, ToastsContainer } from "@/components/layout";
import { AuthenticationMixin } from "@/store/mixins";
import { Watch } from "vue-property-decorator";
import { getTribesInformationRepository } from "./services/repositories";
import {
  TribesInformationFilters,
  TribesInformationRepositoryFilters,
} from "./services/repositories/tribesInformationRepository";

declare const APP_VERSION: string;
declare const APP_BUILD: string;

@Options({
  components: {
    Navbar,
    ToastsContainer,
  },
})
export default class Main extends mixins(AuthenticationMixin) {
  get isLoading(): boolean {
    return !this.$store.state.ready;
  }

  get isFullPage(): boolean {
    return (
      this.$route.meta.isFullPage !== undefined &&
      this.$route.meta.isFullPage === true
    );
  }

  @Watch("isAuthenticated")
  onAuthenticationChanged(): void {
    const promises: Promise<unknown>[] = [];
    const filter: TribesInformationRepositoryFilters =
      new TribesInformationFilters();
    filter.privateMessaging = true;
    getTribesInformationRepository().find(filter);
    // .then((collection) => {
    //   if (collection.length > 0) {
    //     getMessagingInstance();
    //   }
    // });
    if (this.$route.name !== "Home") {
      return;
    }
    if (this.$ability.can("read", "Pro")) {
      promises.push(this.$store.dispatch("internals/fetchTribes"));
      promises.push(this.$store.dispatch("organization/fetchMembers"));
    }

    if (this.$ability.can("read", "Visibility")) {
      promises.push(this.$store.dispatch("externals/fetchTribes"));
    }

    Promise.all(promises).then(() => {
      if (this.isPro && this.hasProTribes) {
        return this.$router.push({ name: "InternalNoTribeSelected" });
      }

      if (this.isPro && this.hasOrgMembers) {
        return this.$router.push({ name: "OrganizationMembers" });
      }

      if (this.isVisility && this.hasVisibilityTribes) {
        return this.$router.push({ name: "ExternalNoTribeSelected" });
      }

      if (this.hasProTribes) {
        return this.$router.push({ name: "InternalNoTribeSelected" });
      }

      if (this.hasOrgMembers) {
        return this.$router.push({ name: "OrganizationMembers" });
      }
    });
  }

  get hasVisibilityTribes(): boolean {
    return (
      this.$store.state.externals !== undefined &&
      !this.$store.state.externals.loadingTribes &&
      this.$store.state.externals.tribes !== undefined &&
      this.$store.state.externals.tribes.length > 0
    );
  }

  get hasProTribes(): boolean {
    return (
      this.$store.state.internals !== undefined &&
      !this.$store.state.internals.loadingTribes &&
      this.$store.state.internals.tribes !== undefined &&
      this.$store.state.internals.tribes.length > 0
    );
  }

  get hasOrgMembers(): boolean {
    return (
      this.$store.state.organization !== undefined &&
      !this.$store.state.organization.loadingMembers &&
      this.$store.state.organization.members !== undefined &&
      this.$store.state.organization.members.length > 0
    );
  }

  get isPro(): boolean {
    return (
      this.$ability.can("create", "OrganizationAdmin") &&
      this.$ability.can("read", "Pro") &&
      this.$ability.cannot("read", "Visibility")
    );
  }

  get isVisility(): boolean {
    return (
      this.$ability.can("create", "TribeInformation") &&
      this.$ability.can("read", "Visibility") &&
      this.$ability.cannot("read", "Pro")
    );
  }
  get appVersion(): string {
    return `${APP_VERSION} (${APP_BUILD})`;
  }
}
