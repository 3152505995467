
import { ToastEvent } from "@/store/models";
import { Toast } from "bootstrap";
import dayjs from "dayjs";
import { Vue, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ToastElement extends Vue {
  @Prop({})
  eventId?: string;

  fromTime = "";

  get event(): ToastEvent | undefined {
    if (this.eventId) {
      return this.$store.state.events?.list[this.eventId];
    }
    return undefined;
  }

  get needHide(): boolean {
    if (this.event?.content?.type === "progress") {
      return false;
    }
    switch (this.event?.level) {
      case "error":
      case "warning":
        return false;
      default:
        return true;
    }
  }

  get toastClass(): string {
    let trans = "";
    switch (this.event?.level) {
      case "error":
        trans = "danger";
        break;
      case "success":
        trans = "primary";
        break;
      case "warning":
        trans = "warning";
        break;
    }
    return `bg-${trans}`;
  }

  get getPercent(): number {
    if (this.event?.content?.type === "progress") {
      const { progress, total } = this.event.content.data as {
        progress: number;
        total: number;
      };
      return progress / total;
    }
    return 0;
  }

  updateFromTime(): string {
    return dayjs().to(this.event?.creationDate).toString();
  }

  private initToast() {
    if (this.event?.id === undefined) {
      return;
    }
    const element = document.getElementById(this.event.id);
    if (element === null) {
      setTimeout(() => {
        this.initToast();
      }, 100);
      return;
    }
    element.addEventListener("hidden.bs.toast", () => {
      this.$store.dispatch("events/remove", this.event);
    });
    let t = Toast.getInstance(element);
    if (t === null) {
      t = new Toast(element);
    }

    t.show();

    const tick = () => {
      this.fromTime = this.updateFromTime();
      setTimeout(tick, 3600);
    };
    tick();
  }

  mounted(): void {
    // console.log("monted", this.event?.id);
    this.initToast();
  }

  // beforeUpdate() {
  //   console.log("beforeUpdate", this.event?.id);
  // }

  // updated() {
  //   console.log("updated", this.event?.id);
  // }

  // unmounted() {
  //   console.log("unmonted", this.event?.id);
  // }
}
