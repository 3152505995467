import { OrganizationUsersGroup } from "@/store/models";
import AbstractSubOrgRepository from "./abstractSubOrgRepository";

export default class OrgAdminsGroupsRepository extends AbstractSubOrgRepository<OrganizationUsersGroup> {
  constructor() {
    super({
      subject: "OrganizationAdmin",
      type: "organizations",
      subType: "usersgroups",
      endpoint: "{id}/usersgroups",
      TypeConstructor: OrganizationUsersGroup,
    });
  }
}
