import { TribesState, State } from "vue";
import { Module } from "vuex";

import createTribeModule from "./tribesAbstract";

export default function createExternalStoreModule(): Module<
  TribesState,
  State
> {
  return createTribeModule("external");
}
