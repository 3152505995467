import { Options, Vue } from "vue-class-component";
import { VerifiedStates } from "@/services/repositories/orgMembersRepository";
import SearchField from "@/components/fields/Search.vue";
import CheckField from "@/components/fields/Check.vue";

@Options({
  components: {
    SearchField,
    CheckField,
  },
})
export default class OrgMembersSearchMixin extends Vue {
  get isMembersRoute(): boolean {
    return this.$route.name == "OrganizationMembers";
  }

  get membersVerified(): VerifiedStates {
    return (
      this.$store.state.organization?.membersFilters?.verifiedState ||
      VerifiedStates.none
    );
  }

  set membersVerified(value: VerifiedStates) {
    this.$store.dispatch("organization/changeMembersFilters", {
      verified: value,
      search: this.membersSearch,
      groupIds: this.membersSelectedGroupId,
      descendant: this.membersDescendant,
    });
  }

  get membersSearch(): string {
    return this.$store.state.organization?.membersFilters?.search || "";
  }

  set membersSearch(value: string) {
    this.$store.dispatch("organization/changeMembersFilters", {
      search: value,
      verified: this.membersVerified,
      groupIds: this.membersSelectedGroupId,
      descendant: this.membersDescendant,
    });
  }

  get membersSelectedGroupId(): string[] | undefined {
    if (this.$store.state.organization?.selectedMembersGroupId) {
      return [this.$store.state.organization?.selectedMembersGroupId];
    }
    return undefined;
  }

  get membersDescendant(): boolean | undefined {
    return this.$store.state.organization?.membersFilters?.descendant;
  }

  updateMembersDescendant(descendant: boolean) {
    this.$store.dispatch("organization/setMembersGroup", undefined);
    this.$store.dispatch("organization/changeMembersFilters", {
      groupIds: undefined,
      descendant: descendant,
    });
  }
}
