import { Options, Vue } from "vue-class-component";
import SearchField from "@/components/fields/Search.vue";

@Options({
  components: {
    SearchField,
  },
})
export default class OrgMembersSearchMixin extends Vue {
  get isAdminsRoute(): boolean {
    return this.$route.name == "OrganizationAdmins";
  }

  get adminsSearch(): string {
    return this.$store.state.organization?.adminsFilters?.search || "";
  }

  set adminsSearch(value: string) {
    this.$store.dispatch("organization/changeAdminsFilters", {
      search: value,
    });
  }

  get adminsDescendant(): boolean | undefined {
    return this.$store.state.organization?.adminsFilters?.descendant;
  }

  updateAdminsDescendant(descendant: boolean) {
    this.$store.dispatch("organization/setAdminsGroup", undefined);
    this.$store.dispatch("organization/changeAdminsFilters", {
      groupIds: undefined,
      descendant: descendant,
    });
  }
}
