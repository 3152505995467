import { AbstractPopulatableModel } from "./abstractModel";

interface TribeDiscussionResponse {
  avatarUrl: string;
}

export default class TribeDiscussion extends AbstractPopulatableModel {
  public type = "TribeDiscussion";
  id?: string;
  organizationId?: string;
  name?: string;
  avatar?: string | File;
  // avatarUrl?: string

  adminGroup?: string | null;

  public populate(data: Partial<TribeDiscussionResponse>): void {
    this.avatar = data.avatarUrl;
  }

  // eslint-disable-next-line
  public toJSON(toExclude?: string[]): Record<string, any> {
    return super.toJSON(["avatar", "avatarUrl"]);
  }
}
