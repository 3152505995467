import { getEnv } from "@/utils";
import { App, ref } from "vue";

interface NewVersionCallback {
  (
    previous: MobileAppVersion,
    newest: MobileAppVersion
  ): Promise<MobileAppVersion>;
}

interface MobileAppVersionProviderPluginOptions {
  url: string;
  updateTimer?: number;
  newVersionCallback?: NewVersionCallback;
}

interface MobileAppVersionAvailabilities {
  ios: boolean;
  android: boolean;
}

interface MobileAppVersionDetails {
  features: Record<string, string>;
  availabilities: MobileAppVersionAvailabilities;
}

export interface MobileAppVersion {
  ready: boolean;
  minimal: string;
  latest: string;
  features: Record<string, string>;
  versions: Record<string, MobileAppVersionDetails>;
}

const defaultOptions: Partial<MobileAppVersionProviderPluginOptions> = {
  updateTimer: 9000000,
};

export class MobileAppVersionProviderPlugin {
  private options: MobileAppVersionProviderPluginOptions;
  private app!: App;

  constructor(options: MobileAppVersionProviderPluginOptions) {
    this.options = { ...defaultOptions, ...options };
  }

  install(app: App): void {
    this.app = app;
    this.update();
  }

  update(): void {
    const mobileApp =
      this.app.config.globalProperties.$mobileApp ||
      ref({
        ready: false,
        minimal: "",
        latest: "",
        features: {},
        versions: {},
      });
    mobileApp.ready = false;
    fetch(this.options.url)
      .then((response) => {
        response.json().then((data: Partial<MobileAppVersion>) => {
          mobileApp.minimal = data.minimal || mobileApp.minimal;
          mobileApp.latest = data.latest || mobileApp.latest;
          mobileApp.features = data.features || mobileApp.features;
          mobileApp.versions = data.versions || mobileApp.versions;
          this.app.config.globalProperties.$mobileApp = mobileApp;
          if (this.options.newVersionCallback) {
            this.triggerNewVersion(mobileApp);
          }
        });
      })
      .finally(() => {
        mobileApp.ready = true;
        setTimeout(() => this.update(), this.options.updateTimer);
      });
  }

  private triggerNewVersion(
    newestMobileAppVersion: MobileAppVersion
  ): Promise<MobileAppVersion> {
    if (!(this.options.newVersionCallback instanceof Function)) {
      return Promise.reject("Callback function is not set");
    }
    const previousMobileAppVersion =
      this.app.config.globalProperties.$mobileApp;
    if (
      previousMobileAppVersion.latest &&
      newestMobileAppVersion.latest &&
      previousMobileAppVersion.latest !== newestMobileAppVersion.latest
    ) {
      return this.options.newVersionCallback(
        previousMobileAppVersion,
        newestMobileAppVersion
      );
    }
    return Promise.reject("Mobile App versions missing");
  }
}

function createMobileAppVersionProviderPlugin(
  options: MobileAppVersionProviderPluginOptions
) {
  return new MobileAppVersionProviderPlugin(options);
}

export default createMobileAppVersionProviderPlugin({
  url: getEnv("mobileAppVersionUrl"),
});
