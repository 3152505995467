import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import { getEnv } from "@/utils";
import { DateTimeFormats } from "@intlify/core-base";

function loadLocaleMessages(): { [x: string]: LocaleMessages<VueMessageType> } {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: { [x: string]: LocaleMessages<VueMessageType> } = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

function loadLocaleDates(): DateTimeFormats {
  const localesDateFormat = require.context(
    "@/locales/dates",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const dateFormats: DateTimeFormats = {};
  localesDateFormat.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      dateFormats[locale] = localesDateFormat(key).default;
    }
  });
  return dateFormats;
}

export default createI18n({
  messages: loadLocaleMessages(),
  legacy: false,
  globalInjection: true,
  locale: getEnv("i18nLocale", "en"),
  fallbackLocale: getEnv("i18nFallbackLocale", "en"),
  datetimeFormats: loadLocaleDates(),
});
