import { EventsState, State } from "vue";
import { Toast } from "bootstrap";
import { Action, Getter, Module, MutationTree } from "vuex";
import { ToastEvent } from "../models";

function generateStates(): EventsState {
  return {
    list: {},
  };
}

function generateMutations(): MutationTree<EventsState> {
  return {
    add(state, event: ToastEvent) {
      state.list[event.id] = event;
    },
    remove(state, event: ToastEvent) {
      delete state.list[event.id];
    },
    update(state, event: ToastEvent) {
      if (state.list[event.id]) {
        state.list[event.id] = event.clone();
      }
    },
  };
}

function generateActions(): Record<string, Action<EventsState, State>> {
  return {
    remove({ commit }, event: ToastEvent) {
      const element = document.getElementById(event.id) as HTMLElement;
      const t = Toast.getInstance(element);
      if (element.offsetParent !== null) {
        t?.hide();
        return;
      }
      commit("remove", event);
    },
  };
}

function generateGetters(): Record<string, Getter<EventsState, State>> {
  return {
    hasEvent(state): boolean {
      return Object.keys(state.list).length > 0;
    },
  };
}

export default function createEventsModule(): Module<EventsState, State> {
  return {
    namespaced: true,
    state: generateStates(),
    mutations: generateMutations(),
    actions: generateActions(),
    getters: generateGetters(),
  };
}
