import { Profile } from "vue";
import { Vue } from "vue-class-component";
import { Organization } from "./models";

export class AuthenticationMixin extends Vue {
  get isAuthenticated(): boolean {
    return this.$store?.state.user?.authenticated || false;
  }

  get userProfile(): Profile {
    return this.$store.state.user?.profile || {};
  }

  get userOrganization(): Organization {
    const orgs: Organization[] | undefined =
      this.$store.state.user?.organizations;
    if (orgs && orgs.length > 0) {
      return orgs[0];
    }
    throw new Error("No organization was set for this user");
  }

  logout(): void {
    this.$auth.logout();
  }
}
