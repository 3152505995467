import { reactive } from "vue";
import Links from "./links";

export class CollectionInfo {
  public size = 0;

  public totalElements = 0;

  public totalPages = 0;

  public number = 0;

  constructor(content?: Partial<CollectionInfo>) {
    if (content) {
      Object.assign(this, {
        size: content.size || this.size,
        totalElements: content.totalElements || this.totalElements,
        totalPages: content.totalPages || this.totalPages,
        number: content.number || this.number,
      });
    }
  }
}

export default class Collection<T> {
  private lockedNext = false;

  constructor(
    protected content: T[] = [],
    private collectionInfo: CollectionInfo = new CollectionInfo(),
    private pLinks: Links = new Links(),
    private loader?: (collection: Collection<T>) => Promise<Collection<T>>,
    public readonly furtherData?: Record<string, unknown>
  ) {
    this.content = this.content.map((element) =>
      reactive(element as object)
    ) as T[];
  }

  get length(): number {
    return this.collectionInfo.totalElements;
  }

  get hasNext(): boolean {
    return this.content.length < this.collectionInfo.totalElements;
  }

  get hasNextPage(): boolean {
    return this.collectionInfo.number < this.collectionInfo.totalPages;
  }

  get entries(): T[] {
    return this.content;
  }

  get info(): CollectionInfo {
    return this.collectionInfo;
  }

  get links(): Links {
    return this.pLinks;
  }

  get loading(): boolean {
    return this.lockedNext;
  }

  public clone(): Collection<T> {
    return new Collection<T>(
      [...this.content],
      new CollectionInfo(this.collectionInfo),
      new Links(this.links),
      this.loader
    );
  }

  public push(element: T): Collection<T> {
    this.content.push(element);
    this.info.size += 1;
    this.info.totalElements += 1;
    return this;
  }

  public remove(element: T): Collection<T> {
    const index = this.indexOf(element);
    this.content.splice(index, 1);
    this.info.size -= 1;
    this.info.totalElements -= 1;
    return this;
  }

  public indexOf(element: T): number {
    const index = this.entries.indexOf(element);
    if (index === -1) {
      throw new Error(`Element ${element} not in array`);
    }
    return index;
  }

  public loadNext(): Promise<Collection<T>> {
    if (!this.hasNext || this.lockedNext) {
      return Promise.resolve(this);
    }

    if (this.loader) {
      this.lockedNext = true;

      return this.loader(this).then((col) => {
        this.collectionInfo = col.collectionInfo;
        this.pLinks = col.links;
        this.content = this.content.concat(col.entries);
        this.lockedNext = false;
        return Promise.resolve(this);
      });
    }

    return Promise.reject(new Error("No NextLoader function is defined"));
  }
}
