import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { getOIDCInstance } from "@/plugins/oidc";

declare const APP_VERSION: string;

function defaultResponseRejected(error: AxiosError): void {
  const oidc = getOIDCInstance();
  switch (error.code) {
    case AxiosError.ECONNABORTED:
    case AxiosError.ERR_NETWORK:
    case AxiosError.ETIMEDOUT:
      console.error(error);
      throw new Error(`API Response: Network error`);
  }

  if (!error.response || !error.response.status) {
    throw new Error(
      `API Response: Internal error, request not ended correctly`
    );
  }

  switch (error.response.status) {
    case 401:
      if (oidc) {
        oidc.removeUser();
        oidc.signinRedirect();
      }
      console.error(`API Response: Need authentication`);
      return;
    default:
      console.error(error);
      throw error;
  }
}

export default function createDefaultClient(
  config: AxiosRequestConfig
): AxiosInstance {
  const client: AxiosInstance = axios.create({
    ...config,
    ...{
      withCredentials: false,
    },
  });

  client.interceptors.request.use(async (config) => {
    /**
     * Treebal-Client header
     * @deprecated
     */
    config.headers["X-App"] = config.headers[
      "Treebal-Client"
    ] = `interface-pro v${APP_VERSION}`;

    const oidc = getOIDCInstance();
    if (oidc) {
      const user = await oidc.getUser();
      if (user !== null) {
        config.headers.Authorization = `Bearer ${user.access_token}`;
        config.headers["X-Uid"] = `${user.profile.sub}`;
      }
    }
    return config;
  });

  client.interceptors.response.use(undefined, defaultResponseRejected);

  return client;
}
