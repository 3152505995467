import { TribeMember } from "@/store/models";
import AbstractSubTribeRepository from "./abstractSubTribeRepository";

export default class TribeDiscussionMembersRepository extends AbstractSubTribeRepository<TribeMember> {
  constructor() {
    super({
      subject: "TribeDiscussionMembers",
      type: "messaging",
      subType: "members",
      endpoint: "rooms/{id}/members",
      TypeConstructor: TribeMember,
    });
  }

  public save(): Promise<TribeMember> {
    return Promise.reject("No implemented");
  }

  public delete(): Promise<TribeMember> {
    return Promise.reject("No implemented");
  }

  public add(memberIds: string[]): Promise<void> {
    if (!this.abilities.can("create", this.options.subject)) {
      return Promise.reject(new Error(`Adding members is not allowed`));
    }
    return this.api.client.post(`${this.endpoint}`, {
      messagingIds: memberIds,
    });
  }

  public remove(memberIds: string[]): Promise<void> {
    if (!this.abilities.can("delete", this.options.subject)) {
      return Promise.reject(new Error(`Removing member is not allowed`));
    }
    return this.api.client.delete(`${this.endpoint}`, {
      data: {
        messagingIds: memberIds,
      },
    });
  }
}
