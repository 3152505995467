import { IRoomEvent, IStateEvent } from "../types";
import ChatRoomMember from "./members";

export interface ChatRoomReaction {
  id: string;
  content: string;
  sender: ChatRoomMember;
  date: Date;
}

export interface ChatRoomReactionsCollection {
  [reactionId: string]: ChatRoomReactionCollection;
}

export default class ChatRoomReactionCollection {
  private rank: { [reaction: string]: number } = {};

  constructor(private collection: Array<IRoomEvent | IStateEvent> = []) {
    this.compute();
    this.sort();
  }

  add(event: IRoomEvent | IStateEvent): void {
    this.appendToCollection(event);
    this.sort();
  }

  merge(collection: Array<IRoomEvent | IStateEvent>): void {
    collection.forEach((event) => this.appendToCollection(event));
    this.sort();
  }

  get iterators(): Array<IRoomEvent | IStateEvent> {
    return [...this.collection];
  }

  get total(): number {
    if (this.rank === undefined) {
      return 0;
    }
    const values = Object.values(this.rank);
    if (values.length === 0) {
      return 0;
    }
    return Object.values(this.rank).reduce((prev, current) => prev + current);
  }

  get top(): { [reaction: string]: number } {
    const top: { [reaction: string]: number } = {};
    Object.keys(this.rank)
      .slice(0, 3)
      .forEach((reaction) => (top[reaction] = this.rank[reaction]));
    return top;
  }

  private sort(): void {
    const nextRank: { [reaction: string]: number } = {};
    Object.keys(this.rank)
      .map((key) => {
        return { reaction: key, count: this.rank[key] };
      })
      .sort((itemA, itemB) => {
        if (itemA.count < itemB.count) {
          return -1;
        }
        if (itemA.count > itemB.count) {
          return 1;
        }
        return 0;
      })
      .forEach((item) => (nextRank[item.reaction] = item.count));
    this.rank = nextRank;
  }

  private compute(): void {
    this.collection.forEach((event) => this.appendEventToRank(event));
  }

  private appendEventToRank(event: IRoomEvent | IStateEvent): void {
    if (event.content["m.relates_to"] && event.content["m.relates_to"].key) {
      const key = event.content["m.relates_to"].key;
      if (this.rank[key] === undefined) {
        this.rank[key] = 0;
      }
      this.rank[key] = this.rank[key] + 1;
    }
  }

  private removeEventToRank(event: IRoomEvent | IStateEvent): void {
    if (event.content["m.relates_to"] && event.content["m.relates_to"].key) {
      const key = event.content["m.relates_to"].key;
      this.rank[key] = this.rank[key] - 1;
      if (this.rank[key] === 0) {
        delete this.rank[key];
      }
    }
  }

  private appendToCollection(incomingEvent: IRoomEvent | IStateEvent): void {
    for (const index in this.collection) {
      const previousEvent = this.collection[index];
      if (previousEvent.sender === incomingEvent.sender) {
        this.collection[index] = incomingEvent;
        this.removeEventToRank(previousEvent);
        this.appendEventToRank(incomingEvent);
        return;
      }
    }
    this.collection.push(incomingEvent);
    this.appendEventToRank(incomingEvent);
  }
}
