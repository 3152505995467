import { API } from "./apiAbstract";
import MessagingApi from "./messagingApi";
import OrganizationsApi from "./organizationsApi";
import PublicTribesApi from "./publicTribesApi";
import UserApi from "./userApi";
import PublicationsApi from "./publicationsApi";

let orgApi: OrganizationsApi;
let publicTribesApi: PublicTribesApi;
let userApi: UserApi;
let messagingApi: MessagingApi;
let publicationsApi: PublicationsApi;

export function getOrganizationsApi(): OrganizationsApi {
  if (orgApi === undefined) {
    orgApi = new OrganizationsApi();
  }
  return orgApi;
}

export function getPublicTribesApi(): PublicTribesApi {
  if (publicTribesApi === undefined) {
    publicTribesApi = new PublicTribesApi();
  }
  return publicTribesApi;
}

export function getUserApi(): UserApi {
  if (userApi === undefined) {
    userApi = new UserApi();
  }
  return userApi;
}

export function getMessagingApi(): MessagingApi {
  if (messagingApi === undefined) {
    messagingApi = new MessagingApi();
  }
  return messagingApi;
}

export function getPublicationsApi(): PublicationsApi {
  if (publicationsApi === undefined) {
    publicationsApi = new PublicationsApi();
  }
  return publicationsApi;
}

export function getAPI(name: string): API {
  switch (name) {
    case "organization":
    case "organizations":
      return getOrganizationsApi();
    case "public-tribes":
    case "publictribes":
      return getPublicTribesApi();
    case "user":
    case "users":
      return getUserApi();
    case "messaging":
      return getMessagingApi();
    case "publications":
      return getPublicationsApi();
    default:
      throw new Error(`No API '${name}' found`);
  }
}

export { type API };
