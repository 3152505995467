import { createApp } from "vue";
import {
  i18n,
  oidc,
  casl,
  breakpoints,
  i18nCountries,
  features,
  mobileAppVersion,
  chat,
  sentry,
} from "./plugins";
import Main from "./Main.vue";
// import "./registerServiceWorker";
import router from "./router";
import { store, key } from "./store";

createApp(Main)
  .use(breakpoints)
  .use(i18n)
  .use(i18nCountries)
  .use(store, key)
  .use(router)
  .use(features)
  .use(mobileAppVersion)
  .use(oidc, router, store)
  .use(casl)
  .use(chat, store)
  .use(sentry, router)
  .mount("#main");
