import { getEnv } from "@/utils";
import { App } from "vue";
import Countries, {
  Alpha2Code,
  Alpha3Code,
  LocalizedCountryNames,
  LocaleData,
} from "i18n-iso-countries";

type I18nCountriesPluginOptions = {
  locale: string;
};

class I18nCountriesPlugin {
  options: I18nCountriesPluginOptions;

  constructor(options: I18nCountriesPluginOptions) {
    this.options = options;
  }

  async install(app: App) {
    Countries.registerLocale(
      await require(`i18n-iso-countries/langs/${this.options.locale}.json`)
    );
    app.config.globalProperties.$countries = new ProxyI18nCountries();
  }
}

export interface I18nCountries {
  getName(countryCode: string | number | Alpha2Code | Alpha3Code): string;
  getNames(): LocalizedCountryNames<{ select: "official" }>;
  getAlpha2Code(name: string): string;
  registerLocale(localeData: LocaleData): void;
}

class ProxyI18nCountries implements I18nCountries {
  getName(countryCode: string | number | Alpha2Code | Alpha3Code): string {
    return Countries.getName(countryCode, getEnv("i18nLocale", "en"));
  }

  getNames(): LocalizedCountryNames<{ select: "official" }> {
    return Countries.getNames(getEnv("i18nLocale", "en"));
  }

  getAlpha2Code(name: string): string {
    return Countries.getAlpha2Code(name, getEnv("i18nLocale", "en"));
  }

  registerLocale(localeData: LocaleData): void {
    Countries.registerLocale(localeData);
  }
}

function createI18nCountriesPlugin(
  options: I18nCountriesPluginOptions
): I18nCountriesPlugin {
  return new I18nCountriesPlugin(options);
}

export default createI18nCountriesPlugin({
  locale: getEnv("i18nLocale", "en"),
});
