import { getOIDCInstance } from "@/plugins/oidc";
// eslint-disable-next-line
function dbPromisify(request: IDBOpenDBRequest | IDBRequest): Promise<any> {
  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
}

type StoreCall = (
  txMode: IDBTransactionMode,
  callback: (store: IDBObjectStore) => Promise<string | void>
  // eslint-disable-next-line
) => Promise<any>;

class FileStorage {
  private store!: StoreCall;
  constructor(dbName: string, storeName: string) {
    if (!indexedDB) {
      throw new Error("IndexDB do not exist");
    }
    this.setStore(dbName, storeName);
  }

  set(key: string, value: Blob): Promise<void> {
    return this.store("readwrite", (store) => {
      store.put(value, key);
      return new Promise((resolve, reject) => {
        const tx = store.transaction;
        tx.oncomplete = () => resolve();
        tx.onabort = tx.onerror = () => reject(tx.error);
      });
    });
  }

  get(key: string): Promise<Blob> {
    return this.store("readonly", (store) => dbPromisify(store.get(key)));
  }

  private async setStore(dbName: string, storeName: string): Promise<void> {
    const request = indexedDB.open(dbName);
    request.onupgradeneeded = () => request.result.createObjectStore(storeName);
    const dbp = dbPromisify(request);
    this.store = (txMode, callback) => {
      return dbp.then((db) =>
        callback(db.transaction(storeName, txMode).objectStore(storeName))
      );
    };
  }
}

let defaultFileStorage: FileStorage;

function defaultGetStore(): FileStorage | undefined {
  try {
    if (!defaultFileStorage) {
      defaultFileStorage = new FileStorage("secured", "securedFiles");
    }
    return defaultFileStorage;
  } catch (err) {
    return;
  }
}

function fetchWithAuthentication(
  url: string,
  token: string
): Promise<Response> {
  const headers = new Headers();
  headers.set("Authorization", `Bearer ${token}`);
  return fetch(url, { headers }).then((response) => {
    if (!response.ok) {
      return Promise.reject(
        new Error(
          "Secured file - Secured Response error"
          // { cause: response },
        )
      );
    }
    return response;
  });
}

type SecuredFileOptions = {
  toStore?: boolean;
};

const defaultSecuredFileOptions: SecuredFileOptions = {
  toStore: false,
};

export function isSecuredUrl(url: string): boolean {
  return url ? url.includes("/api/v1/") || url.includes("_matrix") : false;
}

export default async function getSecuredFile(
  url: string,
  options?: SecuredFileOptions
): Promise<Response> {
  const opts: SecuredFileOptions = {
    ...defaultSecuredFileOptions,
    ...(options || {}),
  };

  if (!isSecuredUrl(url)) {
    return fetch(url).then((response) => {
      if (!response.ok) {
        return Promise.reject(
          new Error(
            "Secured file - Response error"
            // { cause: response },
          )
        );
      }
      return response;
    });
  }

  const oidc = getOIDCInstance();
  if (oidc === undefined) {
    return Promise.reject(new Error("Secured file - OIDC not ready"));
  }

  const user = await oidc.getUser();
  if (user === null) {
    return Promise.reject(new Error("Secured file - User is not defined"));
  }

  if (opts.toStore === false) {
    return fetchWithAuthentication(url, user.access_token);
  }

  const store = defaultGetStore();
  if (store === undefined) {
    return Promise.reject(new Error("Secured file - Secured store not found"));
  }

  const src = await store.get(url);
  if (src) {
    return new Response(src);
  }

  return fetchWithAuthentication(url, user.access_token).then(
    async (response) => {
      store.set(url, await response.clone().blob());
      return response;
    }
  );
}
