import { OrganizationMember, Estimation } from "@/store/models";
import { UsersRepositoryFilters } from "./abstractRepository";
import AbstractSubOrgRepository from "./abstractSubOrgRepository";

export enum VerifiedStates {
  none = 0,
  isVerified = 1,
  isUnverified = 2,
}

export const VerifiedStatesValues = [undefined, true, false];

export class OrgMembersRepositoryFilters extends UsersRepositoryFilters {
  verified?: boolean;

  get verifiedState(): VerifiedStates {
    if (this.verified === undefined) {
      return VerifiedStates.none;
    }
    return this.verified
      ? VerifiedStates.isVerified
      : VerifiedStates.isUnverified;
  }
}

export default class OrgMembersRepository extends AbstractSubOrgRepository<OrganizationMember> {
  constructor() {
    super({
      subject: "OrganizationMember",
      type: "organizations",
      subType: "members",
      endpoint: "{id}/members",
      TypeConstructor: OrganizationMember,
    });
  }

  public resetCode(model: OrganizationMember): Promise<OrganizationMember> {
    if (!this.abilities.can("update", this.options.subject)) {
      return Promise.reject(
        new Error(
          `Update ${this.options.type} with id ${model.id} is not allowed`
        )
      );
    }

    return this.api.client
      .post(`${this.endpoint}/${model.id}/resetcode`)
      .then((response) =>
        Promise.resolve(this.createModel(response.data) as OrganizationMember)
      );
  }

  public notify(model: OrganizationMember, phone: string): Promise<void> {
    if (!this.abilities.can("update", this.options.subject)) {
      return Promise.reject(
        new Error(
          `Update ${this.options.type} with id ${model.id} is not allowed`
        )
      );
    }

    return this.api.client.post(
      `${this.endpoint}/${model.id}/notificationcode`,
      {
        mobileNumber: phone,
      }
    );
  }

  public getCodeCampaign(): Promise<Estimation> {
    return this.api.client
      .get(`${this.endpoint}/notverified/codecampaign`)
      .then((response) => Promise.resolve(new Estimation(response.data)));
  }

  public sendCodeCampaign(): Promise<void> {
    return this.api.client.post(`${this.endpoint}/notverified/codecampaign`);
  }
}
