import { getTribeStateByType, TribeInformationType } from "@/lib/tribes";
import {
  getRepository,
  getTribeDiscussionMembersRepository,
  getTribeRepository,
} from "@/services/repositories";
import { RepositoryFilters } from "@/services/repositories/abstractRepository";
import {
  TribesInformationFilters,
  TribesInformationRepositoryFilters,
} from "@/services/repositories/tribesInformationRepository";
import { capitalize } from "@/utils";
import { State, TribesState } from "vue";
import { Action, Module, MutationTree, Store } from "vuex";
import {
  Collection,
  Publication,
  TribeDiscussion,
  TribeInformation,
  TribeLink,
  TribeMember,
  TribeTypes,
} from "../models";

type StatesAllowedAlone = "tribe" | "publication";

type StatesAllowedSubCollections = "publications" | "members" | "tribeLinks";

type StatesAllowedCollections = "tribes" | StatesAllowedSubCollections;

type StatesAllowed = StatesAllowedAlone | StatesAllowedCollections;

type StatesCapitalized =
  | "Tribes"
  | "Tribe"
  | "Publications"
  | "Publication"
  | "Members";

const StatesNames: StatesAllowed[] = [
  "tribes",
  "tribe",
  "publications",
  "publication",
  "members",
  "tribeLinks",
];

function generateMutationsState(
  stateName: StatesAllowed,
  mutations: MutationTree<TribesState>
): void {
  mutations[`setFetched${capitalize(stateName)}`] = (
    state: TribesState,
    collection: Collection<TribeInformation> &
      Collection<TribeDiscussion> &
      Collection<Publication> &
      Collection<TribeMember> &
      Collection<TribeLink> &
      TribeInformation &
      Publication
  ) => {
    state[stateName] = collection;
  };
  generateMutationsStateLoading(stateName, mutations);
}

function generateCollectionMutationsState(
  stateName: StatesAllowedSubCollections | StatesAllowedCollections,
  mutations: MutationTree<TribesState>
): void {
  mutations[`next${capitalize(stateName)}`] = (state: TribesState) => {
    state[stateName]?.loadNext();
  };
}

function generateMutationsStateLoading(
  stateName: StatesAllowed,
  mutations: MutationTree<TribesState>
): void {
  mutations[`loading${capitalize(stateName)}`] = (state: TribesState) => {
    state[`loading${capitalize(stateName) as StatesCapitalized}`] = true;
  };
  mutations[`${stateName}Loaded`] = (state: TribesState) => {
    state[`loading${capitalize(stateName) as StatesCapitalized}`] = false;
  };
}

function generateActionsStatesSubCollection(
  stateName: StatesAllowedSubCollections,
  actions: Record<string, Action<TribesState, State>>,
  mode: TribeInformationType
): void {
  actions[`fetch${capitalize(stateName)}`] = function (
    this: Store<State>,
    { commit },
    payload: { id: string; filter?: RepositoryFilters }
  ) {
    commit(`loading${capitalize(stateName)}`);
    if (stateName === "members") {
      const type =
        this.state[getTribeStateByType(mode)]?.tribeType ||
        TribeTypes.Information;
      if (type === TribeTypes.Discussion) {
        return (
          getTribeDiscussionMembersRepository()
            .setTribe(payload.id)
            .find(payload.filter)
            .then((collection) => {
              commit(`setFetched${capitalize(stateName)}`, collection);
            })
            // .catch(() => {
            //   dispatch("events/add", resourceLoadingErrorEvent(stateName));
            // })
            .finally(() => {
              commit(`${stateName}Loaded`);
            })
        );
      }
    }

    return (
      getTribeRepository(stateName)
        .setTribe(payload.id)
        .find(payload.filter)
        .then((collection) => {
          commit(`setFetched${capitalize(stateName)}`, collection);
        })
        // .catch(() => {
        //   dispatch("events/add", resourceLoadingErrorEvent(stateName));
        // })
        .finally(() => {
          commit(`${stateName}Loaded`);
        })
    );
  };
}

function generateActionsStateCollection(
  stateName: StatesAllowedCollections,
  actions: Record<string, Action<TribesState, State>>,
  mode: TribeInformationType
): void {
  actions[`fetch${capitalize(stateName)}`] = function (
    this: Store<State>,
    { commit },
    payload?: TribesInformationRepositoryFilters
  ) {
    commit(`loading${capitalize(stateName)}`);
    let filters = payload;
    if (filters === undefined) {
      filters = new TribesInformationFilters();
    }
    filters.external = mode === "external";
    const state =
      this.state[getTribeStateByType(mode)]?.tribeType ||
      TribeTypes.Information;
    return (
      getRepository(state)
        .find(filters)
        .then((collection) => {
          commit(`setFetched${capitalize(stateName)}`, collection);
        })
        // .catch(() => {
        //   commit("events/add", resourceLoadingErrorEvent(stateName));
        // })
        .finally(() => {
          commit(`${stateName}Loaded`);
        })
    );
  };
}

function generateActionsStatesAlone(
  stateName: StatesAllowedAlone,
  actions: Record<string, Action<TribesState, State>>
): void {
  actions[`fetch${capitalize(stateName)}`] = function (
    this: Store<State>,
    { commit, dispatch },
    payload: string
  ) {
    commit(`loading${capitalize(stateName)}`);
    return (
      getRepository(stateName)
        .getById(payload)
        .then((model) => {
          commit(`setFetched${capitalize(stateName)}`, model);
          if (stateName === "tribe") {
            dispatch("fetchTribeLinks", { id: payload });
          }
        })
        // .catch(() => {
        //   commit("events/add", resourceLoadingErrorEvent(stateName));
        // })
        .finally(() => {
          commit(`${stateName}Loaded`);
        })
    );
  };
}

function generateStates(): TribesState {
  return {
    tribeType: TribeTypes.Information,
    loadingMembers: false,
    loadingPublication: false,
    loadingPublications: false,
    loadingTribe: false,
    loadingTribes: false,
    loadingTribeLinks: false,
  };
}

function generateMutations(): MutationTree<TribesState> {
  const mutations: MutationTree<TribesState> = {};
  StatesNames.forEach((state) => {
    generateMutationsState(state, mutations);
  });
  generateCollectionMutationsState("members", mutations);
  generateCollectionMutationsState("tribes", mutations);
  generateCollectionMutationsState("publications", mutations);
  generateCollectionMutationsState("tribeLinks", mutations);
  mutations["setSelectorTribe"] = function (
    state: TribesState,
    payload?: TribeInformation
  ) {
    state.selectorTribe = payload;
  };
  mutations["setTribeType"] = function (
    state: TribesState,
    payload: TribeTypes
  ) {
    state.tribeType = payload;
  };
  return mutations;
}

function generateActions(
  mode: TribeInformationType
): Record<string, Action<TribesState, State>> {
  const actions: Record<string, Action<TribesState, State>> = {};
  generateActionsStatesAlone("tribe", actions);
  generateActionsStatesAlone("publication", actions);
  generateActionsStatesSubCollection("members", actions, mode);
  generateActionsStatesSubCollection("publications", actions, mode);
  generateActionsStatesSubCollection("tribeLinks", actions, mode);
  generateActionsStateCollection("tribes", actions, mode);
  actions["changeTribeType"] = function (
    this: Store<State>,
    { commit, dispatch },
    payload?: string
  ) {
    if (Object.values(TribeTypes).includes(payload as TribeTypes)) {
      commit("setTribeType", payload);
      dispatch("fetchTribes");
    }
  };
  return actions;
}

export default function createTribeModule(
  mode: TribeInformationType
): Module<TribesState, State> {
  return {
    namespaced: true,
    state: generateStates(),
    mutations: generateMutations(),
    actions: generateActions(mode),
  };
}
