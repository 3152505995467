import { getEnv } from "@/utils";
import APIAbstract from "./apiAbstract";

export default class PublicTribesApi extends APIAbstract {
  constructor() {
    super({
      baseUrl: `${getEnv("treebalApiUrlTribe", "notSet")}`,
      basePath: "api/v1/public-tribes",
    });
  }
}
