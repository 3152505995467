
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ProvideFeatureDecisions extends Vue {
  @Prop({ required: true })
  private readonly features!: string;

  get haveExpectedFeatures(): boolean {
    let haveExpected = true;
    this.features
      .split(",")
      .map((feature) => feature.trim())
      .forEach((feature) => {
        if (!this.$features.enabled(feature)) {
          haveExpected = false;
        }
      });

    return haveExpected;
  }
}
