
import { Vue } from "vue-class-component";
import { ModelSync, Prop } from "vue-property-decorator";

enum CheckStates {
  noFilter = 0,
  checked = 1,
  unChecked = 2,
}

export default class CheckField extends Vue {
  @ModelSync("state", "update:state", { type: Number })
  stateValue!: number;

  @Prop({ default: "" }) private titleNoFilter!: string;
  @Prop({ default: "" }) private titleChecked!: string;
  @Prop({ default: "" }) private titleUnchecked!: string;

  get title(): string {
    switch (this.stateValue) {
      case CheckStates.checked:
        return this.titleChecked;
      case CheckStates.unChecked:
        return this.titleUnchecked;
      default:
        return this.titleNoFilter;
    }
  }

  changeState(): void {
    switch (this.stateValue) {
      case CheckStates.noFilter:
        return this.setFieldState(CheckStates.checked);
      case CheckStates.checked:
        return this.setFieldState(CheckStates.unChecked);
      case CheckStates.unChecked:
        return this.setFieldState(CheckStates.noFilter);
      default:
        return;
    }
  }

  private setFieldState(state: CheckStates): void {
    this.stateValue = state;
  }
}
