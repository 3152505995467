import { AbstractModel } from ".";

export default class Organization extends AbstractModel {
  type = "organization";
  id?: string;
  name?: string;
  pro?: boolean;
  visibility?: boolean;
  messageDuration?: number;
  video?: boolean;
  locked?: boolean;
  lockedDate?: Date;
  adminsGroups?: boolean;
  membersGroups?: boolean;
  membersGroup?: string;
  adminGroup?: string;
  smsCredits?: number;
  anonymousTribes?: boolean;
}
