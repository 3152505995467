import { getEnv } from "@/utils";
import APIAbstract from "./apiAbstract";

export default class MessagingApi extends APIAbstract {
  constructor() {
    super({
      baseUrl: `${getEnv("treebalApiUrlMessaging", "notSet")}`,
      basePath: "api/v1/messaging",
    });
  }
}
