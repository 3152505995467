
import { mixins, Options } from "vue-class-component";
import { Organization } from "@/store/models";
import OrgMembersSearchMixin from "@/mixins/forms/orgMembersSearch";
import OrgAdminsSearchMixin from "@/mixins/forms/orgAdminsSearch";
import { OrganizationMixin } from "@/lib/orgs";

@Options({})
export default class OrgNav extends mixins(
  OrgMembersSearchMixin,
  OrgAdminsSearchMixin,
  OrganizationMixin
) {
  get membersCount(): number {
    return this.$store.state.organization?.members?.length || 0;
  }

  get adminsCount(): number {
    return this.$store.state.organization?.admins?.length || 0;
  }

  get isMemberVisible(): boolean {
    return this.org?.pro !== undefined && this.org.pro === true;
  }

  get org(): Organization | undefined {
    return this.$store.state.user?.organizations[0];
  }
}
