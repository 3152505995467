import { TribeLink } from "@/store/models";
import AbstractSubTribeRepository from "./abstractSubTribeRepository";

export default class TribeLinksRepository extends AbstractSubTribeRepository<TribeLink> {
  constructor() {
    super({
      subject: "TribeInformation",
      endpoint: "{id}/links",
      type: "publictribes",
      subType: "links",
      TypeConstructor: TribeLink,
    });
  }
}
