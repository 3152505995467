import { Collection, Publication, PublicationSchedule } from "@/store/models";
import { AbstractRepository } from "./abstractRepository";

export default class PublicationScheduleRepository extends AbstractRepository<PublicationSchedule> {
  protected id!: string;

  constructor() {
    super({
      subject: "TribeInformationPublications",
      endpoint: "publications/{id}",
      type: "publictribes",
      TypeConstructor: PublicationSchedule,
    });
  }

  public save(): Promise<PublicationSchedule> {
    return Promise.reject("No implemented");
  }

  public delete(): Promise<PublicationSchedule> {
    return Promise.reject("No implemented");
  }

  public getById(): Promise<PublicationSchedule> {
    return Promise.reject("No implemented");
  }

  public find(): Promise<Collection<PublicationSchedule>> {
    return Promise.reject("No implemented");
  }

  public publish(publication: Publication): Promise<PublicationSchedule> {
    if (!this.abilities.can("publish", this.options.subject)) {
      return Promise.reject(
        new Error(`Publishing publication ${publication.title} is not allowed.`)
      );
    }
    if (publication.id) {
      this.id = publication.id;
      return this.api.client
        .post(`${this.endpoint}/publish`)
        .then((response) => {
          return Promise.resolve(this.createModel(response.data));
        });
    }
    return Promise.reject("Missing publication id");
  }

  public schedule(
    publication: Publication,
    date: Date
  ): Promise<PublicationSchedule> {
    if (!this.abilities.can("schedule", this.options.subject)) {
      return Promise.reject(
        new Error(
          `Scheduling on publication ${publication.title} is not allowed.`
        )
      );
    }
    if (publication.id) {
      this.id = publication.id;
      return this.api.client
        .post(`${this.endpoint}/schedule`, { scheduleDate: date })
        .then((response) => {
          return Promise.resolve(this.createModel(response.data));
        });
    }
    return Promise.reject("Missing publication id");
  }

  public unschedule(publication: Publication): Promise<void> {
    if (!this.abilities.can("schedule", this.options.subject)) {
      return Promise.reject(
        new Error(
          `Unscheduling on publication ${publication.title} is not allowed.`
        )
      );
    }
    if (publication.id) {
      this.id = publication.id;
      return this.api.client.delete(`${this.endpoint}/schedule`);
    }
    return Promise.reject("Missing publication id");
  }

  public scheduled(publication: Publication): Promise<PublicationSchedule> {
    if (!this.abilities.can("schedule", this.options.subject)) {
      return Promise.reject(
        new Error(
          `Reading scheduling fo publication ${publication.title} is not allowed.`
        )
      );
    }
    if (publication.id) {
      this.id = publication.id;
      return this.api.client
        .get(`${this.endpoint}/schedule`)
        .then((response) => {
          return Promise.resolve(this.createModel(response.data));
        });
    }
    return Promise.reject("Missing publication id");
  }
}
