import { AxiosInstance } from "axios";
import createDefaultClient from "./client";

export type APIOptions = {
  baseUrl: string;
  basePath: string;
};

export interface API {
  client: AxiosInstance;
  options: APIOptions;
}

export default abstract class APIAbstract {
  client: AxiosInstance;
  options: APIOptions;

  constructor(options: APIOptions) {
    this.options = options;
    this.client = createDefaultClient({
      baseURL: options.baseUrl,
    });
  }
}
