
import { TribeModeMixin } from "@/lib/tribes";
import { Collection, TribeDiscussion } from "@/store/models";
import { TribesState } from "vue";
import SecuredImage from "@/components/secured/SecuredImage.vue";
import { mixins, Options } from "vue-class-component";

@Options({
  components: {
    SecuredImage,
  },
})
export default class TribeInformationList extends mixins(TribeModeMixin) {
  get tribes(): Collection<TribeDiscussion> | undefined {
    if (this.modeState) {
      return this.$store.state[this.modeState]
        ?.tribes as Collection<TribeDiscussion>;
    }
    return undefined;
  }

  set currentTribe(tribe: TribeDiscussion | undefined) {
    if (this.modeState) {
      this.$store.commit(`${this.modeState}/setFetchedTribe`, tribe);
    }
  }

  get currentTribe(): TribeDiscussion | undefined {
    if (this.modeState) {
      return (this.$store.state[this.modeState] as TribesState)
        .selectorTribe as TribeDiscussion;
    }
    return undefined;
  }

  get tribesContent(): TribeDiscussion[] {
    return this.tribes?.entries || [];
  }

  clickItem(
    itemTribe: TribeDiscussion,
    navigate: (href: string) => void,
    href: string
  ) {
    this.currentTribe = itemTribe;
    navigate(href);
  }

  private scrollToSelected(): void {
    if (this.tribeURLId !== undefined) {
      const e = document.getElementById("tribeItem-" + this.tribeURLId);
      if (e !== null) e.scrollIntoView();
    }
  }
  updated(): void {
    this.scrollToSelected();
  }

  mounted(): void {
    this.scrollToSelected();
    if (this.$el && this.$el.getAttribute("listener") !== "true") {
      this.$el.addEventListener("scroll", () => {
        if (
          this.$el.offsetHeight + this.$el.scrollTop >=
            this.$el.scrollHeight - 10 &&
          this.tribes?.hasNext &&
          !this.loading
        ) {
          this.$store.commit(`${this.modeState}/nextTribes`);
        }
      });
      this.$el.setAttribute("listener", "true");
    }
  }
}
