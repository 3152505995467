
import { TribeModeMixin } from "@/lib/tribes";
import { Collection, TribeInformation } from "@/store/models";
import { TribesState } from "vue";
import SecuredImage from "@/components/secured/SecuredImage.vue";
import { mixins, Options } from "vue-class-component";

@Options({
  components: {
    SecuredImage,
  },
})
export default class TribeInformationList extends mixins(TribeModeMixin) {
  get tribes(): Collection<TribeInformation> | undefined {
    if (this.modeState) {
      return this.$store.state[this.modeState]
        ?.tribes as Collection<TribeInformation>;
    }
    return undefined;
  }

  set currentTribe(tribe: TribeInformation | undefined) {
    if (this.modeState) {
      this.$store.commit(`${this.modeState}/setSelectorTribe`, tribe);
    }
  }

  get currentTribe(): TribeInformation | undefined {
    if (this.modeState) {
      return (this.$store.state[this.modeState] as TribesState).selectorTribe;
    }
    return undefined;
  }

  get tribesContent(): TribeInformation[] {
    return this.tribes?.entries || [];
  }

  getNotifications(tribe: string): number {
    return this.$store.state.chat?.notifications[tribe] || 0;
  }

  private scrollToSelected(): void {
    if (this.tribeURLId !== undefined) {
      const e = document.getElementById("tribeItem-" + this.tribeURLId);
      if (e !== null) e.scrollIntoView();
    }
  }
  updated(): void {
    this.scrollToSelected();
  }

  mounted(): void {
    this.scrollToSelected();
    if (this.$el && this.$el.getAttribute("listener") !== "true") {
      this.$el.addEventListener("scroll", () => {
        if (
          this.$el.offsetHeight + this.$el.scrollTop >=
            this.$el.scrollHeight - 10 &&
          this.tribes?.hasNext &&
          !this.loading
        ) {
          this.$store.commit(`${this.modeState}/nextTribes`);
        }
      });
      this.$el.setAttribute("listener", "true");
    }
  }
}
