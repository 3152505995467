export class Link {
  public href = "";
}

export default class Links {
  public first!: Link;

  public prev!: Link;

  public self!: Link;

  public next!: Link;

  public last!: Link;

  constructor(data?: Partial<Links>) {
    if (data) {
      this.first = data.first || this.first;
      this.prev = data.prev || this.prev;
      this.self = data.self || this.self;
      this.next = data.next || this.next;
      this.last = data.last || this.last;
    }
  }
}
