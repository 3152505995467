const convert = (envName: string) => {
  return envName
    .split("")
    .map((letter, idx) => {
      return isNaN(Number(letter)) && letter.toUpperCase() === letter
        ? `${idx !== 0 ? "_" : ""}${letter.toLowerCase()}`
        : letter;
    })
    .join("")
    .toUpperCase();
};

export default function getEnv(name: string, fallback?: string): string {
  return (
    window?.configs?.[name] ||
    process.env[`VUE_APP_${convert(name)}`] ||
    fallback ||
    ""
  );
}
