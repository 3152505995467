import { getEnv } from "@/utils";
import APIAbstract from "./apiAbstract";

export default class OrganizationsApi extends APIAbstract {
  constructor() {
    super({
      baseUrl: `${getEnv("treebalApiUrlOrganization", "notSet")}`,
      basePath: "api/v1/organization",
    });
  }
}
