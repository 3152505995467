import { getEnv } from "@/utils";
import { FeatureList, FeaturesState, State } from "vue";
import { Action, GetterTree, Module } from "vuex";
import { store } from "..";

type AvailableOs = "android" | "ios" | "interfacePro";
export enum FeatureKnown {
  oneToOne = "1to1",
  oneToOneReactions = "1to1-reactions",
  oneToOneFiles = "1to1-files",
  oneToOneIsReadBy = "1to1-is-read-by",
  publicationMedia = "publication-media",
  notificationUserPro = "notification-user-pro",
  tribesDiscussions = "tribes-discussions",
  publicationReaction = "publication-reaction",
}

interface FeatureResponse {
  os: Record<AvailableOs, boolean>;
  rules?: Record<string, string[]>;
}

type FeatureRule = (response: FeatureResponse, state: State) => boolean;

const orgsRuleChecker = (data: FeatureResponse, state: State) => {
  const rules = data.rules;
  if (rules && rules.orgs && rules.orgs.length > 0) {
    const orgs = state.user?.organizations;
    if (orgs && orgs.length > 0) {
      return (
        orgs
          .map((org) => org.id)
          .filter((orgId) => (orgId ? rules.orgs.includes(orgId) : false))
          .length > 0
      );
    }
  }
  return false;
};

const featureRules: Record<string, FeatureRule> = {
  [FeatureKnown.publicationMedia]: orgsRuleChecker,
  [FeatureKnown.publicationReaction]: orgsRuleChecker,
  [FeatureKnown.notificationUserPro]: orgsRuleChecker,
  [FeatureKnown.tribesDiscussions]: orgsRuleChecker,
  [FeatureKnown.oneToOneFiles]: orgsRuleChecker,
  [FeatureKnown.oneToOneIsReadBy]: orgsRuleChecker,
  [FeatureKnown.oneToOneReactions]: orgsRuleChecker,
};

function generateStates(): FeaturesState {
  return { available: {}, loaded: false };
}

function generateMutations() {
  return {
    update(state: FeaturesState, features: FeatureList) {
      state.available = features;
    },
    loading(state: FeaturesState) {
      state.loaded = false;
    },
    loaded(state: FeaturesState) {
      state.loaded = true;
    },
  };
}

function generateActions(): Record<string, Action<FeaturesState, State>> {
  return {
    get({ commit, dispatch, rootState }) {
      if (
        rootState.user?.organizations === undefined ||
        rootState.user.organizations.length === 0
      ) {
        return setTimeout(() => dispatch("get"), 2000);
      }
      commit("loading");
      const url = `${getEnv("treebalStaticApiUrl")}/config/features`;
      return fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Feature fetch list error: ${response.status} - ${response.statusText}`
            );
          }

          return response.json();
        })
        .then((list: Record<string, FeatureResponse>) => {
          const features: FeatureList = {};
          for (const feature in list) {
            if (list[feature].os.interfacePro !== undefined) {
              features[feature] =
                list[feature].os.interfacePro ||
                (featureRules[feature]
                  ? featureRules[feature](list[feature], rootState)
                  : false);
            }
          }
          commit("update", features);
        })
        .finally(() => {
          commit("loaded");
          store.dispatch("checkLoadStep");
        });
    },
  };
}

function generateGetters(): GetterTree<FeaturesState, State> {
  return {
    enabled:
      (state) =>
      (feature: string): boolean => {
        return state.available[feature] === undefined
          ? false
          : state.available[feature];
      },
  };
}

export default function createFeaturesModule(): Module<FeaturesState, State> {
  return {
    namespaced: true,
    state: generateStates(),
    mutations: generateMutations(),
    actions: generateActions(),
    getters: generateGetters(),
  };
}
