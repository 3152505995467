
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BlobImagesManager from "@/lib/blobImagesManager";
import { getChatClient } from "@/services/chat";
import { MXCMethods } from "@/services/chat/client";

const blobImgMng = new BlobImagesManager();

@Options({})
export default class SecuredImage extends Vue {
  @Prop({ require: true })
  readonly src!: string;

  @Prop({ default: MXCMethods.Full })
  readonly mode!: MXCMethods;

  @Prop()
  readonly attr?: string;

  isLoading = true;
  isEmpty = false;
  private finaleSrc = "";

  beforeMount(): void {
    // console.log(`[SecuredImage] - mounted - ${this.src}`);
    this.finaleSrc = this.src + "";
    if (!this.src) {
      this.isEmpty = true;
      return;
    }

    if (this.finaleSrc.startsWith("mxc://")) {
      this.finaleSrc = getChatClient().getMXCContentUrl(
        this.src,
        this.mode,
        this.attr
      );
    }

    blobImgMng
      .get(this.finaleSrc)
      .then((url) => {
        this.$el.src = url;
      })
      .catch(() => {
        this.isEmpty = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  beforeUnmount(): void {
    blobImgMng.unset(this.finaleSrc);
  }
}
