import { store } from "@/store";
import { FeatureKnown } from "@/store/modules/features";
import { SpecificEventType, StandardEventType } from "../event";
import { ChatRoom } from "../models";
import { Category, IRoomEvent, IStateEvent } from "../types";
import { ChatRoomReactionCollection } from "../models";
import Parser, { ParsingWay } from "./types";

export default class EventParser implements Parser {
  constructor(public room: ChatRoom, public debug = false) {}

  parse(
    event: IRoomEvent | IStateEvent,
    parsingWay = ParsingWay.Forward
  ): Promise<EventParser> {
    switch (event.type) {
      case StandardEventType.RoomName:
        if (parsingWay === ParsingWay.Forward) {
          this.room.tribeId = event.content.name;
        }
        break;
      case SpecificEventType.PublicTribe:
        if (parsingWay === ParsingWay.Forward) {
          this.room.tribeId = event.properties?.content.publicTribeId;
        }
        break;
      case SpecificEventType.Anonymous:
        if (parsingWay === ParsingWay.Forward) {
          this.room.anonymous =
            event.properties?.content.anonymous === true || false;
        }
        break;
      case StandardEventType.RoomMember:
        this.RoomMemberParsing(event, parsingWay);
        break;
      case StandardEventType.Reaction:
        this.ReactionsParsing(event);
        break;
      case StandardEventType.RoomRedaction:
        this.RedactionParsing(event);
        break;
    }
    return Promise.resolve(this);
  }

  private RoomMemberParsing(
    event: IRoomEvent | IStateEvent,
    parsingWay: ParsingWay
  ): void {
    if (parsingWay === ParsingWay.Backward) {
      return;
    }
    switch (event.content.membership) {
      case Category.Join:
        return this.RoomMemberJoinParsing(event);
      case Category.Leave:
        return this.RoomMemberLeaveParsing(event);
    }
  }

  private RoomMemberJoinParsing(event: IRoomEvent | IStateEvent): void {
    const member = this.room.members[event.sender];
    // if (this.debug) console.log('[ChatEventsParser] - Join', event);
    if (member) {
      member.displayName = event.content.displayname || member.displayName;
      member.avatarURL = event.content.avatar_url || member.avatarURL;
      this.room.members[event.sender] = member;
      if (this.debug)
        console.log(
          "[ChatEventsParser] - Join - update member",
          event.content,
          this.room.members[event.sender]
        );
      return;
    }

    if (Object.keys(this.room.members).length === 0) {
      this.room.creationTime = new Date(
        event.origin_server_ts || event.properties?.origin_server_ts || 0
      );
    }

    this.room.members[event.sender] = {
      id: event.sender,
      displayName: event.content.displayname,
      avatarURL: event.content.avatar_url,
      leaved: false,
    };
  }

  private RoomMemberLeaveParsing(event: IRoomEvent | IStateEvent): void {
    this.room.members[event.sender] = this.room.members[event.sender] || {
      id: event.sender,
    };
    this.room.members[event.sender].leaved = true;
  }

  private ReactionsParsing(event: IRoomEvent | IStateEvent): void {
    const featReact = store.getters["features/enabled"](
      FeatureKnown.oneToOneReactions
    );
    if (featReact) {
      if (this.room.reactions === undefined) {
        this.room.reactions = {};
      }
      if (
        event.content["m.relates_to"] &&
        event.content["m.relates_to"].event_id
      ) {
        if (
          this.room.reactions[event.content["m.relates_to"].event_id] ===
          undefined
        ) {
          this.room.reactions[event.content["m.relates_to"].event_id] =
            new ChatRoomReactionCollection([event]);
        } else {
          this.room.reactions[event.content["m.relates_to"].event_id].add(
            event
          );
        }
      }
    }
  }

  private RedactionParsing(event: IRoomEvent | IStateEvent): void {
    if (event.redacts) {
      this.room.redaction.push(event.redacts);
    }
  }
}
