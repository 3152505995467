import { Organization } from "@/store/models";
import { AbstractRepository } from "./abstractRepository";

export default class OrganizationsRepository extends AbstractRepository<Organization> {
  constructor() {
    super({
      subject: "Organization",
      type: "organizations",
      TypeConstructor: Organization,
    });
  }
}
