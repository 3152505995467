import { store } from "@/store";
import { EventType } from "../event";
import { IContent, IRoomEvent, IStateEvent } from "../types";
import ChatRoomMember from "./members";
import ChatRoomReactionCollection from "./reactions";
import ChatRoom from "./rooms";

export enum ChatRoomEventStates {
  NotSent,
  Sent,
  Received,
  ReadByAll,
}

export enum EventReceiptModes {
  Matrix = "matrix",
  Proxy = "proxy",
}

export type EventReceiptMode = EventReceiptModes;

function getEventState(event: IRoomEvent | IStateEvent): ChatRoomEventStates {
  if (event.computed === undefined) {
    return ChatRoomEventStates.NotSent;
  }
  if (event.computed.viewedByAll) {
    return ChatRoomEventStates.ReadByAll;
  }
  if (event.computed.viewedBy && event.computed.viewedBy.length > 0) {
    return ChatRoomEventStates.Received;
  }
  return ChatRoomEventStates.Sent;
}

export default class ChatRoomEvent {
  public state: ChatRoomEventStates;

  constructor(
    public event: IRoomEvent | IStateEvent,
    public roomId: string,
    public sender: ChatRoomMember,
    public mode: EventReceiptMode = EventReceiptModes.Matrix
  ) {
    this.state = getEventState(event);
    if (event.eventId) {
      event.event_id = event.eventId;
    }
  }

  get id(): string {
    return this.event.event_id;
  }

  get content(): IContent {
    return this.event.content;
  }

  get ts(): number {
    return (
      this.event.origin_server_ts ||
      this.event.properties?.origin_server_ts ||
      0
    );
  }

  get date(): Date {
    return new Date(this.ts);
  }

  get type(): EventType {
    return this.event.type as EventType;
  }

  get reactions(): ChatRoomReactionCollection | undefined {
    return this.room?.reactions[this.event.event_id];
  }

  get room(): ChatRoom | undefined {
    if (store.state.chat && store.state.chat.tribesRooms[this.roomId]) {
      const tribeId = store.state.chat?.tribesRooms[this.roomId];
      return store.state.chat.rooms[tribeId][this.roomId];
    }
    return;
  }

  get isDeleted(): boolean {
    return this.room
      ? this.room.redaction.includes(this.event.event_id)
      : false;
  }
}
