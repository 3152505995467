import { TribeModeMixin } from "@/lib/tribes";
import { TribeInformation } from "@/store/models";
import { mixins } from "vue-class-component";
import { ChatRoom, ChatRoomMember } from "./models";

const emojiRE = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;

function getWordFirstCharacter(word: string): string {
  if (word.length === 0) {
    return "";
  }

  const hasEmoji = word.match(emojiRE);
  return hasEmoji !== null && word.indexOf(hasEmoji[0]) === 0
    ? hasEmoji[0]
    : word[0];
}

export class ChatRoomMixin extends mixins(TribeModeMixin) {
  getMember(room: ChatRoom): ChatRoomMember | undefined {
    if (this.modeState === undefined) {
      return { id: "StateNotDefined", leaved: true };
    }
    const orgMessagingId =
      (this.$store.state[this.modeState]?.tribe as TribeInformation)
        ?.messagingId || "";
    return Object.values(room.members).filter(
      (member) => member.id !== orgMessagingId
    )[0];
  }

  getInitials(member: ChatRoomMember): string {
    const nameArr = (member?.displayName || "")
      .split(" ")
      .filter((value) => value !== "");

    if (nameArr.length < 1) {
      return "";
    }

    const firstWord = nameArr[0],
      firstChar = getWordFirstCharacter(firstWord),
      secondWord =
        nameArr.length >= 2 ? nameArr[1] : firstWord.replace(firstChar, ""),
      secondChar = getWordFirstCharacter(secondWord);

    return firstChar + secondChar;
  }
}
