import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import "./class-hooks";

function loadView(viewName: string) {
  return () => import(/* webpackMode: 'lazy' */ `@/views/${viewName}.vue`);
}

function loadTribesView(viewName: string) {
  return () =>
    import(
      /* webpackMode: 'lazy', webpackChunkName: "tribes" */ `@/views/tribes/${viewName}.vue`
    );
}

function loadOrgView(viewName: string) {
  return () =>
    import(
      /* webpackMode: 'lazy', webpackChunkName: "org" */ `@/views/organizations/${viewName}.vue`
    );
}

function loadComponent(componentName: string) {
  return () =>
    import(
      /* webpackMode: 'lazy', webpackChunkName: "components" */ `@/components/${componentName}.vue`
    );
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: loadView("Home"),
  },
  {
    path: "/externals",
    name: "Externals",
    components: {
      default: loadView("SubMenuLayout"),
      modals: loadTribesView("GlobalModalsExternal"),
    },
    children: [
      {
        path: "",
        name: "ExternalNoTribeSelected",
        components: {
          default: loadTribesView("NoTribeSelected"),
          subMenu: loadComponent("layout/TribesSelector"),
        },
      },
      {
        path: "information/:id",
        name: "ExternalTribeInformation",
        components: {
          default: loadTribesView("TribeInformation"),
          subMenu: loadComponent("layout/TribesSelector"),
        },
        children: [
          {
            name: "ExternalTribeInformationRedirect",
            path: "",
            redirect: { name: "ExternalTribeInformationPublications" },
          },
          {
            path: "publications",
            name: "ExternalTribeInformationPublications",
            components: {
              default: loadTribesView("TribePublications"),
            },
          },
          {
            path: "publications/create",
            name: "ExternalTribeInformationPublicationCreate",
            components: {
              default: loadTribesView("TribePublicationForm"),
            },
          },
          {
            path: "publications/:publicationId",
            name: "ExternalTribeInformationPublicationEdit",
            components: {
              default: loadTribesView("TribePublicationForm"),
            },
          },
          {
            path: "discussions",
            name: "ExternalTribeInformationChats",
            components: {
              default: loadTribesView("TribeInformationChats"),
            },
            children: [
              {
                path: ":room",
                name: "ExternalTribeInformationChatRoom",
                components: {
                  default: loadTribesView("TribeInformationChats"),
                },
              },
            ],
          },
          {
            path: "members",
            name: "ExternalTribeInformationMembers",
            components: {
              default: loadTribesView("TribeMembers"),
            },
          },
        ],
      },
    ],
  },
  {
    path: "/internals",
    name: "Internals",
    components: {
      default: loadView("SubMenuLayout"),
      modals: loadTribesView("GlobalModalsInternal"),
    },
    children: [
      {
        path: "",
        name: "InternalNoTribeSelected",
        components: {
          default: loadTribesView("NoTribeSelected"),
          subMenu: loadComponent("layout/TribesSelector"),
        },
      },
      {
        path: "discussions/:id",
        name: "InternalTribeDiscussions",
        components: {
          default: loadTribesView("TribeDiscussions"),
          subMenu: loadComponent("layout/TribesSelector"),
        },
        children: [
          {
            path: "members",
            name: "InternalTribeDiscussionMembers",
            components: {
              default: loadTribesView("TribeDiscussionMembers"),
            },
          },
        ],
      },
      {
        path: "information/:id",
        name: "InternalTribeInformation",
        components: {
          default: loadTribesView("TribeInformation"),
          subMenu: loadComponent("layout/TribesSelector"),
        },
        children: [
          {
            name: "InternalTribeInformationRedirection",
            path: "",
            redirect: { name: "InternalTribeInformationPublications" },
          },
          {
            path: "publications",
            name: "InternalTribeInformationPublications",
            components: {
              default: loadTribesView("TribePublications"),
            },
          },
          {
            path: "publications/create",
            name: "InternalTribeInformationPublicationCreate",
            components: {
              default: loadTribesView("TribePublicationForm"),
            },
          },
          {
            path: "publications/:publicationId",
            name: "InternalTribeInformationPublicationEdit",
            components: {
              default: loadTribesView("TribePublicationForm"),
            },
          },
          {
            path: "discussions",
            name: "InternalTribeInformationChats",
            components: {
              default: loadTribesView("TribeInformationChats"),
            },
            children: [
              {
                path: ":room",
                name: "InternalTribeInformationChatRoom",
                components: {
                  default: loadTribesView("TribeInformationChats"),
                },
              },
            ],
          },
          {
            path: "members",
            name: "InternalTribeInformationMembers",
            components: {
              default: loadTribesView("TribeMembers"),
            },
          },
        ],
      },
    ],
  },
  {
    path: "/organization",
    name: "Organization",
    components: {
      default: loadView("StandardLayout"),
    },
    children: [
      {
        name: "OrganizationRedirection",
        path: "",
        redirect: { name: "OrganizationMembers" },
      },
      {
        path: "members",
        name: "OrganizationMembers",
        components: {
          default: loadOrgView("OrgMembers"),
        },
      },
      {
        path: "admins",
        name: "OrganizationAdmins",
        components: {
          default: loadOrgView("OrgAdmins"),
        },
      },
    ],
  },
  {
    path: "/externals/information/create",
    name: "ExternalTribeInformationCreate",
    components: {
      default: loadTribesView("TribeInformationForm"),
    },
  },
  {
    path: "/externals/information/:id/edit",
    name: "ExternalTribeInformationEdit",
    components: {
      default: loadTribesView("TribeInformationForm"),
    },
  },

  {
    path: "/internals/information/create",
    name: "InternalTribeInformationCreate",
    components: {
      default: loadTribesView("TribeInformationForm"),
    },
  },
  {
    path: "/internals/information/:id/edit",
    name: "InternalTribeInformationEdit",
    components: {
      default: loadTribesView("TribeInformationForm"),
    },
  },
  {
    path: "/internals/information/:id/discussions/full/:room",
    name: "InternalTribeInformationChatRoomAlone",
    components: {
      default: loadTribesView("TribeInformationChatRoom"),
    },
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/externals/information/:id/discussions/full/:room",
    name: "ExternalTribeInformationChatRoomAlone",
    components: {
      default: loadTribesView("TribeInformationChatRoom"),
    },
    meta: {
      isFullPage: true,
    },
  },
  {
    path: "/login",
    redirect: { name: "Home", query: {} },
  },
  {
    path: "/logout",
    redirect: { name: "Home", query: {} },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
