import { OrganizationMembersGroup } from "@/store/models";
import AbstractSubOrgRepository from "./abstractSubOrgRepository";

export default class OrgMembersGroupsRepository extends AbstractSubOrgRepository<OrganizationMembersGroup> {
  constructor() {
    super({
      subject: "OrganizationMember",
      type: "organizations",
      subType: "membersgroups",
      endpoint: "{id}/membersgroups",
      TypeConstructor: OrganizationMembersGroup,
    });
  }
}
