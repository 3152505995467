import { Publication, PublicationsStatus } from "@/store/models";
import {
  RepositoryFilters,
  StandardRepositoryFilters,
} from "./abstractRepository";
import AbstractSubTribeRepository from "./abstractSubTribeRepository";

export interface PublicationsRepositoryFilters extends RepositoryFilters {
  status?: PublicationsStatus[];
  since?: Date;
}

export class PublicationsFilters
  extends StandardRepositoryFilters
  implements PublicationsRepositoryFilters {}

export default class PublicationsRepository extends AbstractSubTribeRepository<Publication> {
  constructor() {
    super({
      subject: "TribeInformationPublications",
      endpoint: "{id}/publications",
      type: "publictribes",
      subType: "publications",
      TypeConstructor: Publication,
    });
    this.FormData = ["media"];
  }
}
