export enum AppVersionStatus {
  Newer = "organization.members.mobileAppStatus.newer",
  UpToDate = "organization.members.mobileAppStatus.upToDate",
  ToUpdate = "organization.members.mobileAppStatus.toUpdate",
  Unknown = "organization.members.mobileAppStatus.unknown",
}

export enum AppVersionState {
  Unknown = "Unknown",
  UpToDate = "UpToDate",
  ToUpdate = "ToUpdate",
  Newer = "Newer",
}

type Version = {
  major: number;
  minor: number;
  patch: number;
};

function decomposeVersion(version: string): Version {
  const aV = version.split(".");
  if (aV.length === 3) {
    return {
      major: parseInt(aV[0]),
      minor: parseInt(aV[1]),
      patch: parseInt(aV[2]),
    };
  }
  throw new Error("Is not a SemVer format");
}

function compareState(current: number, compare: number): AppVersionState {
  if (current < compare) {
    return AppVersionState.ToUpdate;
  }
  if (current > compare) {
    return AppVersionState.Newer;
  }
  return AppVersionState.UpToDate;
}

export default class AppVersion {
  private pBuild = 0;
  private pComputed?: Version;

  constructor(version: string, build: string) {
    this.pBuild = parseInt(build);
    if (version) {
      this.pComputed = decomposeVersion(version);
    }
  }

  get version(): Version {
    return this.pComputed
      ? this.pComputed
      : {
          major: 0,
          minor: 0,
          patch: 0,
        };
  }

  get build(): number {
    return this.pBuild;
  }

  getStateFromVersion(versionToCompare: string): AppVersionState {
    const vC = decomposeVersion(versionToCompare);
    if (!this.pComputed) {
      return AppVersionState.Unknown;
    }
    let compare = compareState(this.pComputed.major, vC.major);
    if (compare !== AppVersionState.UpToDate) {
      return compare;
    }
    compare = compareState(this.pComputed.minor, vC.minor);
    if (compare !== AppVersionState.UpToDate) {
      return compare;
    }
    compare = compareState(this.pComputed.patch, vC.patch);
    return compare;
  }

  getStatusFromVersion(versionToCompare: string): AppVersionStatus {
    const state = this.getStateFromVersion(versionToCompare);
    return AppVersionStatus[state];
  }

  toString(): string {
    return this.pComputed
      ? `${this.pComputed.major}.${this.pComputed.minor}.${this.pComputed.patch}`
      : "";
  }
}
