import {
  getOrgAdminsGroupsRepository,
  getOrgAdminsRepository,
  getOrgMembersRepository,
  getOrgMembersGroupsRepository,
} from "@/services/repositories";
import {
  RepositoryFilters,
  UsersRepositoryFilters,
} from "@/services/repositories/abstractRepository";
import {
  OrgMembersRepositoryFilters,
  VerifiedStates,
  VerifiedStatesValues,
} from "@/services/repositories/orgMembersRepository";
import { OrganizationState, State } from "vue";
import { Action, Module, MutationTree } from "vuex";
import {
  Collection,
  OrganizationMember,
  OrganizationUser,
  OrganizationUsersGroup,
  OrganizationMembersGroup,
} from "../models";

function generateStates(): OrganizationState {
  return {
    loadingAdmins: false,
    loadingAdminsGroups: false,
    loadingMembers: false,
    loadingRequests: false,
    loadingMembersGroups: false,
  };
}

function generateMutations(): MutationTree<OrganizationState> {
  return {
    setAdminsFilters(
      state: OrganizationState,
      filters: UsersRepositoryFilters
    ) {
      state.adminsFilters = filters;
    },
    clearAdminsFilters(state: OrganizationState) {
      state.adminsFilters = undefined;
    },
    setFetchedAdmins(
      state: OrganizationState,
      collection: Collection<OrganizationUser>
    ) {
      state.admins = collection;
    },
    nextAdmins(state: OrganizationState) {
      state.admins?.loadNext();
    },
    loadingAdmins(state: OrganizationState) {
      state.loadingAdmins = true;
    },
    adminsLoaded(state: OrganizationState) {
      state.loadingAdmins = false;
    },
    setFetchedAdminsGroups(
      state: OrganizationState,
      collection: Collection<OrganizationUsersGroup>
    ) {
      state.adminsGroups = collection;
    },
    loadingAdminsGroups(state: OrganizationState) {
      state.loadingAdminsGroups = true;
    },
    adminsGroupsLoaded(state: OrganizationState) {
      state.loadingAdminsGroups = false;
    },
    setAdminsGroup(state: OrganizationState, groupId?: string) {
      state.selectedAdminsGroupId = groupId;
    },
    nextAdminsGroups(state: OrganizationState) {
      state.adminsGroups?.loadNext();
    },
    setMembersFilters(
      state: OrganizationState,
      filters: OrgMembersRepositoryFilters
    ) {
      state.membersFilters = filters;
    },
    clearMembersFilters(state: OrganizationState) {
      state.membersFilters = undefined;
    },
    setFetchedMembers(
      state: OrganizationState,
      collection: Collection<OrganizationMember>
    ) {
      state.members = collection;
    },
    nextMembers(state: OrganizationState) {
      state.members?.loadNext();
    },
    loadingMembers(state: OrganizationState) {
      state.loadingMembers = true;
    },
    membersLoaded(state: OrganizationState) {
      state.loadingMembers = false;
    },
    setFetchedMembersGroups(
      state: OrganizationState,
      collection: Collection<OrganizationMembersGroup>
    ) {
      state.membersGroups = collection;
    },
    loadingMembersGroups(state: OrganizationState) {
      state.loadingMembersGroups = true;
    },
    membersGroupsLoaded(state: OrganizationState) {
      state.loadingMembersGroups = false;
    },
    setMembersGroup(state: OrganizationState, groupId?: string) {
      /*
      if (state.membersFilters) {
        if (groupId) {
          state.membersFilters.groupIds = [groupId];
        } else {
          state.membersFilters.groupIds = undefined;
        }
      } else if (groupId) {
        const filters = new OrgMembersRepositoryFilters();
        filters.groupIds = [groupId];
        state.membersFilters = filters;
      }*/
      state.selectedMembersGroupId = groupId;
    },
    nextMembersGroups(state: OrganizationState) {
      state.membersGroups?.loadNext();
    },
    loadingRequests(state: OrganizationState) {
      state.loadingRequests = true;
    },
    requestsLoaded(state: OrganizationState) {
      state.loadingRequests = false;
    },
  };
}

function generateActions(): Record<string, Action<OrganizationState, State>> {
  return {
    changeAdminsFilters(
      { commit, dispatch },
      payload?: {
        search?: string;
        groupIds?: string[];
        descendant?: boolean;
      }
    ) {
      if (
        !payload ||
        (!payload.search &&
          !payload.groupIds &&
          payload.descendant === undefined)
      ) {
        commit("clearAdminsFilters");
        return dispatch("fetchAdmins");
      }
      const filters = new UsersRepositoryFilters();
      if (payload.search && payload.search.trim().length > 0) {
        filters.search = payload.search;
      }
      if (payload.groupIds && payload.groupIds.length > 0) {
        filters.groupIds = payload.groupIds;
      }
      if (payload.descendant !== undefined) {
        filters.descendant = payload.descendant;
      }
      commit("setAdminsFilters", filters);
      dispatch("fetchAdmins", filters);
    },
    fetchAdminsFilters({ commit, dispatch, rootState }) {
      commit("loadingAdmins");
      dispatch("fetchAdmins", rootState?.organization?.adminsFilters);
    },
    fetchAdmins({ commit }, payload?: RepositoryFilters) {
      commit("loadingAdmins");
      return getOrgAdminsRepository()
        .find(payload)
        .then((collection) => {
          commit("setFetchedAdmins", collection);
        })
        .finally(() => {
          commit("adminsLoaded");
        });
    },
    fetchAdminsGroups({ commit }, payload?: RepositoryFilters) {
      commit("loadingAdminsGroups");
      return getOrgAdminsGroupsRepository()
        .find(payload)
        .then((collection) => {
          commit("setFetchedAdminsGroups", collection);
        })
        .finally(() => {
          commit("adminsGroupsLoaded");
        });
    },
    setAdminsGroup(
      { commit, dispatch, rootState },
      adminsGroup?: OrganizationUsersGroup | string
    ) {
      getOrgAdminsRepository().setGroup(adminsGroup);
      if (!adminsGroup) {
        adminsGroup = rootState.user?.adminGroup;
      }
      commit(
        "setAdminsGroup",
        typeof adminsGroup === "string" ? adminsGroup : adminsGroup?.id || ""
      );
    },
    fetchMembersGroups({ commit }, payload?: RepositoryFilters) {
      commit("loadingMembersGroups");
      return getOrgMembersGroupsRepository()
        .find(payload)
        .then((collection) => {
          commit("setFetchedMembersGroups", collection);
        })
        .finally(() => {
          commit("membersGroupsLoaded");
        });
    },
    setMembersGroup(
      { commit, dispatch, rootState },
      membersGroup?: OrganizationMembersGroup | string
    ) {
      commit(
        "setMembersGroup",
        typeof membersGroup === "string" ? membersGroup : membersGroup?.id || ""
      );
    },
    changeMembersFilters(
      { commit, dispatch },
      payload?: {
        search?: string;
        verified?: VerifiedStates;
        userIds?: string[];
        groupIds?: string[];
        descendant?: boolean;
      }
    ) {
      if (
        !payload ||
        (!payload.search &&
          payload.verified === undefined &&
          !payload.userIds &&
          !payload.groupIds &&
          payload.descendant === undefined)
      ) {
        commit("clearMembersFilters");
        return dispatch("fetchMembers");
      }
      const filters = new OrgMembersRepositoryFilters();
      if (payload.search && payload.search.trim().length > 0) {
        filters.search = payload.search;
      }
      if (payload.verified !== undefined) {
        filters.verified = VerifiedStatesValues[payload.verified];
      }
      if (payload.userIds && payload.userIds.length > 0) {
        filters.userIds = payload.userIds;
      }
      if (payload.groupIds && payload.groupIds.length > 0) {
        filters.groupIds = payload.groupIds;
      }
      if (payload.descendant !== undefined) {
        filters.descendant = payload.descendant;
      }
      commit("setMembersFilters", filters);
      dispatch("fetchMembers", filters);
    },
    fetchMembersFilters({ commit, dispatch, rootState }) {
      commit("loadingMembers");
      dispatch("fetchMembers", rootState?.organization?.membersFilters);
    },
    fetchMembers({ commit }, payload?: RepositoryFilters) {
      commit("loadingMembers");
      return getOrgMembersRepository()
        .find(payload)
        .then((collection) => {
          commit("setFetchedMembers", collection);
        })
        .finally(() => {
          commit("membersLoaded");
        });
    },
    fetchNextMembers({ state }) {
      if (state.members === undefined) {
        return Promise.resolve();
      }
      return state.members.loadNext();
    },
  };
}

export default function createOrganizationModule(): Module<
  OrganizationState,
  State
> {
  return {
    namespaced: true,
    state: generateStates(),
    mutations: generateMutations(),
    actions: generateActions(),
  };
}
