import { TribeDiscussion } from "@/store/models";
import { AbstractRepository } from "./abstractRepository";

export default class TribesDiscussionRepository extends AbstractRepository<TribeDiscussion> {
  constructor() {
    super({
      subject: "TribeDiscussion",
      type: "messaging",
      subType: "rooms",
      endpoint: "rooms",
      TypeConstructor: TribeDiscussion,
    });
  }

  public save(model: TribeDiscussion): Promise<TribeDiscussion> {
    return super.save(model).then((savedModel) => {
      if (model.avatar instanceof File && savedModel.id) {
        return this.saveAvatar(savedModel.id, model.avatar).then(
          () => savedModel
        );
      }
      return savedModel;
    });
  }

  private saveAvatar(roomId: string, file: File): Promise<void> {
    const data = new FormData();
    data.append("avatar", file);
    return this.api.client.put(`${this.endpoint}/${roomId}/avatar`, data, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
}
