import { Model, OrganizationMember, TribeInformation } from "@/store/models";
import { AbstractRepository, Repository } from "./abstractRepository";

export interface SubMemberRepository<M extends Model> extends Repository<M> {
  setMember(user: OrganizationMember | string): SubMemberRepository<M>;
}

export default abstract class AbstractSubMemberRepository<M extends Model>
  extends AbstractRepository<M>
  implements SubMemberRepository<M>
{
  protected userId!: string | undefined;

  public setMember(user: OrganizationMember | string): SubMemberRepository<M> {
    this.userId = typeof user === "string" ? user : user.id;
    return this;
  }
}
