import {
  OrganizationMember,
  TribeInformation,
  Collection,
} from "@/store/models";
import AbstractSubMemberRepository from "./abstractSubMemberRepository";

export default class MemberSubscriptionsRepository extends AbstractSubMemberRepository<TribeInformation> {
  constructor() {
    super({
      subject: "TribeInformation",
      type: "publictribes",
      endpoint: "subscriptions/{userId}",
      TypeConstructor: TribeInformation,
    });
  }

  public save(): Promise<TribeInformation> {
    return Promise.reject("No implemented");
  }

  public delete(): Promise<TribeInformation> {
    return Promise.reject("No implemented");
  }

  public getById(): Promise<TribeInformation> {
    return Promise.reject("No implemented");
  }

  public find(): Promise<Collection<TribeInformation>> {
    return Promise.reject("No implemented");
  }

  public get(): Promise<Collection<TribeInformation>> {
    if (this.userId === "") {
      return Promise.reject(new Error("Missing id parameter"));
    }
    return super.find();
  }
}
