import { Model, Organization } from "@/store/models";
import { AbstractRepository, Repository } from "./abstractRepository";

export interface SubOrgRepository<M extends Model> extends Repository<M> {
  setOrg(org: Organization | string): SubOrgRepository<M>;
}

export default abstract class AbstractSubOrgRepository<M extends Model>
  extends AbstractRepository<M>
  implements SubOrgRepository<M>
{
  protected id!: string | undefined;

  public setOrg(org: Organization | string): SubOrgRepository<M> {
    this.id = typeof org === "string" ? org : org.id;
    return this;
  }
}
