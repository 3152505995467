import { getChatClient } from "@/services/chat";
import { App, State } from "vue";
import { Store } from "vuex";

let store: Store<State>;
const MAX_TRY = 10;
const DELTA_TIME = 1000;

class ChatProviderPlugin {
  install(app: App, vueStore: Store<State>): void {
    store = vueStore;
    Promise.all([this.promiseOrg(), this.promiseFeatures()])
      .then(() => {
        const chatClient = getChatClient();
        chatClient.start(store);
      })
      .catch((reason) => {
        console.error(reason);
      });
  }

  private promiseOrg(): Promise<void> {
    let tries = 0;
    return new Promise((resolve, reject) => {
      function trying() {
        tries = tries + 1;
        if (
          store &&
          store.state &&
          store.state.user?.organizations &&
          store.state.user.organizations.length > 0
        ) {
          tries = 0;
          resolve();
          return;
        }

        if (tries < MAX_TRY) {
          setTimeout(() => {
            trying();
          }, tries * DELTA_TIME);
          return;
        }
        reject(`Organization info is not reachable after ${tries} attempts`);
      }
      trying();
    });
  }

  private promiseFeatures(): Promise<void> {
    let tries = 0;
    return new Promise((resolve, reject) => {
      function trying() {
        tries = tries + 1;
        if (store && store.state && store.state.features?.loaded) {
          tries = 0;
          resolve();
          return;
        }

        if (tries < MAX_TRY) {
          setTimeout(() => {
            trying();
          }, tries * DELTA_TIME);
          return;
        }
        reject(`Features info is not reachable after ${tries} attempts`);
      }
      trying();
    });
  }
}

function createChatProviderPlugin() {
  return new ChatProviderPlugin();
}

export default createChatProviderPlugin();
