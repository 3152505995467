import { AbstractModel } from ".";

export enum TribeMemberStatus {
  Preflighted = "P",
  Subscribed = "S",
}

export default class TribeMember extends AbstractModel {
  userId?: string;

  firstName?: string;

  lastName?: string;

  displayName?: string;

  messagingId?: string;

  state?: TribeMemberStatus;

  avatar?: string;

  avatarUrl?: string;

  orgMember?: boolean;

  isPreflight(): boolean {
    return this.state === TribeMemberStatus.Preflighted;
  }
}
