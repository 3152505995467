import { Model, Publication } from "@/store/models";
import { AbstractRepository, Repository } from "./abstractRepository";

export interface SubPublicationsRepository<M extends Model>
  extends Repository<M> {
  setPublication(
    publication: Publication | string
  ): SubPublicationsRepository<M>;
}

export default abstract class AbstractSubPublicationsRepository<M extends Model>
  extends AbstractRepository<M>
  implements SubPublicationsRepository<M>
{
  protected id!: string;

  public setPublication(
    publication: Publication | string
  ): SubPublicationsRepository<M> {
    this.id = typeof publication === "string" ? publication : publication.id;
    return this;
  }
}
