import {
  Collection,
  Organization,
  OrganizationMember,
  OrganizationMembersGroup,
  OrganizationUser,
  OrganizationUsersGroup,
} from "@/store/models";
import { Vue } from "vue-class-component";

export class OrganizationMixin extends Vue {
  get currentOrganization(): Organization {
    return this.$store.state.user?.organizations[0] || new Organization();
  }

  get isLoadingMembers(): boolean {
    const loading = this.$store.state.organization?.loadingMembers;
    if (loading === undefined) {
      return true;
    }
    return loading;
  }

  get hasMembers(): boolean {
    return this.members ? this.members.length > 0 : false;
  }

  get members(): Collection<OrganizationMember> | undefined {
    return this.$store.state.organization?.members;
  }

  get membersContent(): OrganizationMember[] {
    return this.members?.entries || [];
  }

  get membersGroupsContent(): OrganizationMembersGroup[] {
    return this.membersGroups?.entries || [];
  }

  get membersGroups(): Collection<OrganizationMembersGroup> | undefined {
    return this.$store.state.organization?.membersGroups;
  }

  get isLoadingMembersGroups(): boolean {
    const loading = this.$store.state.organization?.loadingMembersGroups;
    if (loading === undefined) {
      return true;
    }
    return loading;
  }

  get isLoadingAdmins(): boolean {
    const loading = this.$store.state.organization?.loadingAdmins;
    if (loading === undefined) {
      return true;
    }
    return loading;
  }

  get isLoadingRequests(): boolean {
    const loading = this.$store.state.organization?.loadingRequests;
    if (loading === undefined) {
      return true;
    }
    return loading;
  }

  get hasAdmins(): boolean {
    return this.admins ? this.admins.length > 0 : false;
  }

  get admins(): Collection<OrganizationUser> | undefined {
    return this.$store.state.organization?.admins;
  }

  get adminsContent(): OrganizationUser[] {
    return this.admins?.entries || [];
  }

  get isLoadingAdminsGroups(): boolean {
    const loading = this.$store.state.organization?.loadingAdminsGroups;
    if (loading === undefined) {
      return true;
    }
    return loading;
  }

  get adminsGroups(): Collection<OrganizationUsersGroup> | undefined {
    return this.$store.state.organization?.adminsGroups;
  }

  get adminsGroupsTotalNumber(): number | undefined {
    return this.$store.state.organization?.adminsGroups?.length;
  }

  get hasAdminsGroups(): boolean {
    return this.adminsGroups ? this.adminsGroups.length > 0 : false;
  }

  get adminsGroupsContent(): OrganizationUsersGroup[] {
    return this.adminsGroups?.entries || [];
  }
}
