import { AbstractPopulatableModel } from "./abstractModel";
import AppVersion from "./appVersion";

export default class OrganizationMember extends AbstractPopulatableModel {
  type = "OrganizationMember";

  id?: string;

  email?: string;

  contactMobile?: string;

  externalUId?: string;

  registeringCode?: string;

  registeringDate?: number;

  firstName?: string;

  lastName?: string;

  messagingId?: string;

  appBuild?: string;

  appVersion?: string;

  version?: AppVersion;

  groupIds?: string[];

  populate(data: Partial<OrganizationMember>): void {
    if (data.appBuild && data.appVersion) {
      this.version = new AppVersion(data.appVersion, data.appBuild);
    }
  }

  get displayName(): string {
    let displayName = "";

    if (this.firstName || this.lastName) {
      if (this.firstName) {
        displayName += `${this.firstName} `;
      }
      if (this.lastName) {
        displayName += `${this.lastName}`;
      }
    } else {
      if (this.email) {
        displayName += `${this.email} `;
      }
      if (this.contactMobile) {
        displayName += `${this.contactMobile} `;
      }
      if (this.externalUId) {
        displayName += `${this.externalUId} `;
      }
    }
    return displayName;
  }
}
