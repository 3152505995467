import {
  ChatRoom,
  ChatRoomCollection,
  ChatRoomEvent,
  EventReceiptMode,
} from "@/services/chat/models";
import { IRoomEvent, IStateEvent } from "@/services/chat/types";
import { ChatState, State } from "vue";
import { Module } from "vuex";

function getNotificationsCounts(
  tribeRooms: Record<string, ChatRoomCollection>
): Record<string, number> {
  const result: Record<string, number> = {};
  for (const id in tribeRooms) {
    result[id] = Object.values(tribeRooms[id])
      .map((v) => v.unreadNotification)
      .reduce((p, v) => p + v, 0);
  }
  return result;
}

type ActionData = {
  rooms: Record<string, ChatRoomCollection>;
  tribeRooms: Record<string, string>;
};

export default function createChatModule(): Module<ChatState, State> {
  return {
    namespaced: true,
    state: {
      iam: "",
      isReady: false,
      notifications: {},
      rooms: {},
      tribesRooms: {},
    },
    getters: {
      getTribeNotificationCount: (state: ChatState) => (tribeId: string) =>
        state.notifications[tribeId] || 0,
      getTribeRooms: (state: ChatState) => (tribeId: string) =>
        state.rooms[tribeId],
      getRoom: (state: ChatState) => (tribeId: string, roomId: string) =>
        state.rooms[tribeId][roomId],
    },
    mutations: {
      iam(state: ChatState, id: string) {
        state.iam = id;
      },
      systemIsReady(state: ChatState) {
        state.isReady = true;
      },
      updateNotifications(
        state: ChatState,
        notifications: Record<string, number>
      ) {
        state.notifications = notifications;
      },
      saveTribeRoomsRelations(
        state: ChatState,
        tribeRooms: Record<string, string>
      ) {
        state.tribesRooms = tribeRooms;
      },
      saveAllRooms(
        state: ChatState,
        rooms: Record<string, ChatRoomCollection>
      ) {
        state.rooms = rooms;
      },
      saveTribeRooms(state: ChatState, rooms: ChatRoom[]) {
        if (rooms.length > 0) {
          const tribeId = rooms[0]?.tribeId || "unknown";
          const registeredRooms = state.rooms[tribeId];
          rooms.forEach((room) => {
            if (registeredRooms[room.id]) {
              registeredRooms[room.id].update(room);
              return;
            }
            registeredRooms[room.id] = room;
          });
        }
      },
      removeRooms(state: ChatState, rooms: string[]) {
        rooms.forEach((roomId) => {
          const tribeId = state.tribesRooms[roomId];
          delete state.tribesRooms[roomId];
          delete state.rooms[tribeId][roomId];
        });
      },
      removeRoomNotification(
        state: ChatState,
        room: { tribeId: string; roomId: string }
      ) {
        state.rooms[room.tribeId][room.roomId].unreadNotification = 0;
      },
      setNextBatch(state: ChatState, nextBatch: string) {
        state.nextBatch = nextBatch;
      },
      prependRoomMessages(
        state: ChatState,
        data: {
          tribeId: string;
          roomId: string;
          messages: Array<IRoomEvent | IStateEvent>;
          mode: EventReceiptMode;
        }
      ) {
        // console.log("[ChatModule] - Mut - prependRoomMessages", data);
        state.rooms[data.tribeId][data.roomId].prependEvents(
          data.messages,
          data.mode
        );
      },
    },
    actions: {
      saveRooms({ commit }, data: ActionData) {
        commit("saveTribeRoomsRelations", data.tribeRooms);
        commit("saveAllRooms", data.rooms);
        commit("updateNotifications", getNotificationsCounts(data.rooms));
        commit("systemIsReady");
      },
      updateRooms({ commit, state }, data: ActionData) {
        commit("saveTribeRoomsRelations", {
          ...state.tribesRooms,
          ...data.tribeRooms,
        });
        for (const tribeId in data.rooms) {
          commit("saveTribeRooms", Object.values(data.rooms[tribeId]));
        }
        commit("updateNotifications", getNotificationsCounts(state.rooms));
      },
      removeRooms({ commit, state }, rooms: string[]) {
        commit("removeRooms", rooms);
        commit("updateNotifications", getNotificationsCounts(state.rooms));
      },
      removeRoomNotifications({ commit, state }, roomId: string) {
        const tribeId = state.tribesRooms[roomId];
        commit("removeRoomNotification", { tribeId, roomId });
        commit("updateNotifications", getNotificationsCounts(state.rooms));
      },
      prependRoomMessages(
        { commit, state },
        data: {
          roomId: string;
          messages: ChatRoomEvent[];
          mode: EventReceiptMode;
        }
      ) {
        const tribeId = state.tribesRooms[data.roomId];
        commit("prependRoomMessages", {
          tribeId,
          roomId: data.roomId,
          messages: data.messages,
          mode: data.mode,
        });
      },
    },
  };
}
