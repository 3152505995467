
import { TribeModeMixin } from "@/lib/tribes";

import { mixins, Options } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SearchField from "@/components/fields/Search.vue";
import SecuredImage from "@/components/secured/SecuredImage.vue";
import TribesInformationList from "@/components/layout/TribesInformationList.vue";
import TribesDiscussionList from "@/components/layout/TribesDiscussionList.vue";
import ProvideFeatureDecisions from "@/components/layout/ProvideFeatureDecisions.vue";
import { SearchRepositoryFilters } from "@/services/repositories/abstractRepository";
import { TribeTypes } from "@/store/models";

@Options({
  components: {
    SearchField,
    SecuredImage,
    TribesInformationList,
    TribesDiscussionList,
    ProvideFeatureDecisions,
  },
})
export default class tribesSelector extends mixins(TribeModeMixin) {
  @Prop({ require: false, default: "Not Set Title" })
  title!: string;

  created(): void {
    if (!this.hasTribes) {
      this.$store.dispatch(`${this.modeState}/fetchTribes`);
    }
  }

  setToTribeInformation() {
    if (!this.isInformation) {
      this.$store.dispatch(
        `${this.modeState}/changeTribeType`,
        TribeTypes.Information
      );
    }
  }

  setToTribeDiscussion() {
    if (!this.isDiscussion) {
      this.$store.dispatch(
        `${this.modeState}/changeTribeType`,
        TribeTypes.Discussion
      );
    }
  }

  get search(): string {
    return "";
  }

  set search(search: string) {
    const filter = new SearchRepositoryFilters();
    filter.search = search;
    this.$store.dispatch(`${this.modeState}/fetchTribes`, filter);
  }

  private searchReset(): void {
    this.$store.dispatch(`${this.modeState}/fetchTribes`);
  }
}
