export interface SerializableModel {
  toJSON(): Record<string, unknown>;
}

export interface Model extends SerializableModel {
  id?: string | number;
  type: string;
  get(field: string): unknown;
  set(field: string, value: unknown): void;
  clone(): Model;
}

export interface PopulatableModel extends Model {
  discriminator: "populatable";
  populate(data: Record<string, unknown>): void;
}

export abstract class AbstractModel implements Model {
  public type = "abstract";

  public describe(): Array<string> {
    return Object.getOwnPropertyNames(this);
  }

  public toJSON(toExclude?: string[]): Record<string, unknown> {
    let stringsToExclude: string[] = ["_", "type"];
    if (toExclude) {
      stringsToExclude = stringsToExclude.concat(toExclude);
    }

    const obj: Record<string, unknown> = {};
    const keys = this.describe().filter((key: string) =>
      stringsToExclude.every(
        (excludedKey: string) => !key.includes(excludedKey)
      )
    );
    keys.forEach((attr: string) => {
      obj[attr] = (this as Record<string, unknown>)[attr];
    });
    return obj;
  }

  public get(field: string): unknown {
    if (this.describe().includes(field)) {
      const objField = Object.entries(this).find((val) => val[0] === field);
      return objField?.[1];
    }
    return undefined;
  }

  public set(field: string, value: unknown): AbstractModel {
    const obj = this as unknown as Record<string, unknown>;
    if (this.describe().includes(field)) {
      obj[field] = value;
    }
    return this;
  }

  clone(): AbstractModel {
    let clone = new (Object.getPrototypeOf(this).constructor)();
    clone = Object.assign(clone, { ...this });
    return clone;
  }
}

export abstract class AbstractPopulatableModel
  extends AbstractModel
  implements PopulatableModel
{
  discriminator: "populatable";

  constructor() {
    super();
    this.discriminator = "populatable";
  }

  // eslint-disable-next-line
  abstract populate(data: Record<string, any>): void;

  public toJSON(toExclude?: string[]): Record<string, unknown> {
    return super.toJSON([...(toExclude || []), "discriminator"]);
  }

  clone(): AbstractPopulatableModel {
    const clone = super.clone() as AbstractPopulatableModel;
    clone.populate({ ...this });
    return clone;
  }
}

// eslint-disable-next-line
export function isPopulatable(object: any): object is PopulatableModel {
  return object.discriminator === "populatable";
}

export {};
