import getSecuredFile, { isSecuredUrl } from "./securedFile";

class ImagesCollectionStorage {
  private images: Record<string, string> = {};

  get(key: string): string | undefined {
    return this.images[key];
  }

  set(key: string, file: Blob): string {
    this.images[key] = URL.createObjectURL(file);
    return this.images[key];
  }

  delete(key: string): void {
    const url = this.get(key);
    if (url) {
      URL.revokeObjectURL(url);
    }
    delete this.images[key];
  }
}

class ImagesCallsManager {
  private callList: Record<string, Promise<Blob>> = {};

  constructor(public limit = 8) {}

  fetch(url: string): Promise<Blob> {
    if (!Object.keys(this.callList).includes(url)) {
      this.callList[url] = getSecuredFile(url, { toStore: true })
        .then((response) => response.blob())
        .finally(() => delete this.callList[url]);
    }

    return this.callList[url];
  }
}

export default class BlobImagesManager {
  private images: ImagesCollectionStorage;
  private imagesMng: ImagesCallsManager;

  constructor(limit = 8, public debug = false) {
    this.images = new ImagesCollectionStorage();
    this.imagesMng = new ImagesCallsManager(limit);
  }

  get(url: string): Promise<string> {
    if (this.debug) console.log(`[BlobImagesManager] - fetch`, url);

    if (!isSecuredUrl(url)) {
      if (this.debug)
        console.log(`[BlobImagesManager] - fetch - Not a secured URL`, url);
      return Promise.resolve(url);
    }

    const storedValue = this.images.get(url);
    if (storedValue) {
      if (this.debug)
        console.log(`[BlobImagesManager] - fetch - find in storage`, url);
      return Promise.resolve(storedValue);
    }

    if (this.debug)
      console.log(`[BlobImagesManager] - fetch - not yet in blob`, url);

    return this.imagesMng.fetch(url).then((file) => this.images.set(url, file));
  }

  unset(url: string): void {
    if (this.debug) console.log(`[BlobImagesManager] - unset`, url);
    this.images.delete(url);
  }
}
