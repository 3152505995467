import NavbarLayoutComponent from "./Navbar.vue";
import TribesSelectorLayoutComponent from "./TribesSelector.vue";
import OrgDetailsLayoutComponent from "./OrgDetails.vue";
import OrgNavLayoutComponent from "./OrgNav.vue";
import ToastsContainerLayoutComponent from "./ToastsContainer.vue";

const Navbar = NavbarLayoutComponent;
const TribesSelector = TribesSelectorLayoutComponent;
const OrgDetails = OrgDetailsLayoutComponent;
const OrgNav = OrgNavLayoutComponent;
const ToastsContainer = ToastsContainerLayoutComponent;

export { Navbar, TribesSelector, OrgDetails, OrgNav, ToastsContainer };
