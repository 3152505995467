
import { Organization } from "@/store/models";
import { mixins, Options } from "vue-class-component";
import OrgMembersSearchMixin from "@/mixins/forms/orgMembersSearch";
import OrgAdminsSearchMixin from "@/mixins/forms/orgAdminsSearch";

@Options({})
export default class OrgDetails extends mixins(
  OrgMembersSearchMixin,
  OrgAdminsSearchMixin
) {
  get org(): Organization | undefined {
    return this.$store.state.user?.organizations[0];
  }
}
