import { store } from "@/store";
import { App } from "vue";

interface FeatureProviderPluginOptions {
  updateTimer: number;
}

const defaultOptions: FeatureProviderPluginOptions = {
  updateTimer: 900000,
};

export class FeatureProviderPlugin {
  private options: FeatureProviderPluginOptions;

  constructor(options?: FeatureProviderPluginOptions) {
    this.options = { ...defaultOptions, ...(options || {}) };
  }

  install(app: App): void {
    app.config.globalProperties.$features = this;
    this.update();
  }

  enabled(feature: string): boolean {
    return store.getters["features/enabled"](feature);
  }

  update(): void {
    store.dispatch("features/get").then(() => {
      setTimeout(() => this.update(), this.options.updateTimer);
    });
  }
}

function createFeaturesProviderPlugin(options?: FeatureProviderPluginOptions) {
  return new FeatureProviderPlugin(options);
}

export default createFeaturesProviderPlugin();
