import { App } from "vue";

type BreakpointsName = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export type BreakpointsThresholds = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
};

type BreakpointsOptions = {
  mobileBreakpoint: number;
  thresholds: BreakpointsThresholds;
};

export class BreakpointsPlugin {
  public name!: BreakpointsName;

  public only: Record<BreakpointsName, boolean> = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  };

  public andDown: Record<BreakpointsName, boolean> = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  };

  public andUp: Record<BreakpointsName, boolean> = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
  };

  public height = 0;

  public width = 0;

  public mobile = true;

  public options: BreakpointsOptions;

  private resizeTimeout = 0;

  constructor(options: BreakpointsOptions) {
    this.options = options;
  }

  install(app: App): void {
    this.update();
    window.addEventListener(
      "resize",
      () => {
        this.onResize();
      },
      { passive: true }
    );
    app.config.globalProperties.$breakpoints = this;
  }

  update(): void {
    const height = this.getClientHeight();
    const width = this.getClientWidth();
    const thresholds = this.options.thresholds;

    const xs = width < thresholds.sm;
    const sm = width < thresholds.md && !xs;
    const md = width < thresholds.lg && !(sm || xs);
    const lg = width < thresholds.xl && !(md || sm || xs);
    const xl = width < thresholds.xxl && !(lg || md || sm || xs);
    const xxl = width >= thresholds.xxl;

    this.height = height;
    this.width = width;
    this.only = {
      xs: xs,
      sm: sm,
      md: md,
      lg: lg,
      xl: xl,
      xxl: xxl,
    };
    this.andDown = {
      xs: xs && !(sm || md || lg || xl || xxl),
      sm: (xs || sm) && !(md || lg || xl || xxl),
      md: (xs || sm || md) && !(lg || xl || xxl),
      lg: (xs || sm || md || lg) && !(xl || xxl),
      xl: (xs || sm || md || lg || xl) && !xxl,
      xxl: xs || sm || md || lg || xl || xxl,
    };
    this.andUp = {
      xs: xs || sm || md || lg || xl || xxl,
      sm: !xs && (sm || md || lg || xl || xxl),
      md: !(xs || sm) && (md || lg || xl || xxl),
      lg: !(xs || sm || md) && (lg || xl || xxl),
      xl: !(xs || sm || md || lg) && (xl || xxl),
      xxl: !(xs || sm || md || lg || xl) && xxl,
    };

    switch (true) {
      case xs:
        this.name = "xs";
        break;
      case sm:
        this.name = "sm";
        break;
      case md:
        this.name = "md";
        break;
      case lg:
        this.name = "lg";
        break;
      case xl:
        this.name = "xl";
        break;
      default:
        this.name = "xxl";
        break;
    }

    this.mobile = width <= this.options.mobileBreakpoint;
  }

  private onResize(): void {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = window.setTimeout(() => {
      this.update();
    }, 200);
  }

  private getClientWidth(): number {
    if (document.documentElement) {
      return Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
    }
    return window.innerWidth || 0;
  }

  private getClientHeight(): number {
    if (document.documentElement) {
      return Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      );
    }
    return window.innerHeight || 0;
  }
}

function createBreakpointsPlugin(options: BreakpointsOptions) {
  return new BreakpointsPlugin(options);
}

export default createBreakpointsPlugin({
  mobileBreakpoint: 768,
  thresholds: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
  },
});
