import { TribeInformation, TribeInformationStatus } from "@/store/models";
import {
  AbstractRepository,
  RepositoryFilters,
  StandardRepositoryFilters,
} from "./abstractRepository";

export interface TribesInformationRepositoryFilters extends RepositoryFilters {
  organizationIds?: string[];
  name?: string;
  status?: TribeInformationStatus[];
  external?: boolean;
  searchable?: boolean;
  privateMessaging?: boolean;
}

export class TribesInformationFilters
  extends StandardRepositoryFilters
  implements TribesInformationRepositoryFilters {}

type AssetType = "logo" | "image";

export default class TribesInformationRepository extends AbstractRepository<TribeInformation> {
  constructor() {
    super({
      subject: "TribeInformation",
      type: "publictribes",
      TypeConstructor: TribeInformation,
    });
  }

  public save(model: TribeInformation): Promise<TribeInformation> {
    return super.save(model).then((savedModel) => {
      const imgs: Promise<void>[] = [];
      if (model.logo instanceof File && savedModel.id) {
        imgs.push(this.saveAsset(savedModel.id, "logo", model.logo));
      }
      if (model.image instanceof File && savedModel.id) {
        imgs.push(this.saveAsset(savedModel.id, "image", model.image));
      }
      return Promise.all(imgs).then(() => {
        return savedModel;
      });
    });
  }

  private saveAsset(
    tribeId: string,
    assetType: AssetType,
    file: File
  ): Promise<void> {
    const data = new FormData();
    data.append("name", assetType);
    data.append("file", file);
    return this.api.client.post(`${this.endpoint}/${tribeId}/assets`, data, {
      headers: { "content-type": "multipart/form-data" },
    });
  }
}
