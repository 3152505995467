import dayjs from "dayjs";
import { AbstractPopulatableModel } from ".";

export enum ScheduleStatus {
  Archived = 0,
  Ready = 10,
  ToSchedule = 20,
  Scheduled = 30,
  ToPublish = 40,
  Published = 50,
}

export const ScheduleStatusLabel: Record<number, string> = {
  0: "archived",
  10: "ready",
  20: "to-schedule",
  30: "scheduled",
  40: "to-publish",
  50: "published",
};

export default class PublicationSchedule extends AbstractPopulatableModel {
  scheduleDate?: Date;
  status?: ScheduleStatus;
  treebalTargeted?: number;

  get translatedScheduleDate(): string {
    return this.scheduleDate ? dayjs(this.scheduleDate).format("LLLL") : "";
  }

  get isArchived(): boolean {
    return this.status === ScheduleStatus.Archived;
  }

  get isReady(): boolean {
    return this.status === ScheduleStatus.Ready;
  }

  get isToSchedule(): boolean {
    return this.status === ScheduleStatus.ToSchedule;
  }

  get isScheduled(): boolean {
    return this.status === ScheduleStatus.Scheduled;
  }

  get isToPublish(): boolean {
    return this.status === ScheduleStatus.ToPublish;
  }

  get isPublished(): boolean {
    return this.status === ScheduleStatus.Published;
  }

  get isPublishable(): boolean {
    return !this.isScheduled && this.isReady;
  }

  get isEditable(): boolean {
    return (
      this.isReady || this.isScheduled || this.isToSchedule || this.isToPublish
    );
  }

  get statusName(): string {
    let status = this.status ? this.status : -1;
    status = this.status === 0 ? 0 : status;
    return ScheduleStatusLabel[status] || "";
  }

  public populate(data: Partial<PublicationSchedule>): void {
    if (data.scheduleDate) {
      const date =
        typeof data.scheduleDate === "string"
          ? data.scheduleDate
          : data.scheduleDate.toDateString();
      this.scheduleDate = new Date(date);
    }
  }
}
