import { EventType, MsgType, RelationType, RoomType } from "./event";

// interface IOpts {
//   maxTimelineEntries?: number;
// }

export interface IMinimalEvent {
  content: IContent;
  type: EventType | string;
  unsigned?: IUnsigned;
}

export interface IEphemeral {
  events: IMinimalEvent[];
}

/* eslint-disable camelcase */
interface IUnreadNotificationCounts {
  highlight_count?: number;
  notification_count?: number;
}

export interface IRoomEventComputedAttributes {
  viewedBy?: string[];
  viewedByAll?: boolean;
  [attrName: string]: unknown;
}

export interface IRoomEvent extends IEvent {
  event_id: string;
  eventId?: string;
  sender: string;
  origin_server_ts: number;
  computed?: IRoomEventComputedAttributes;
  /** @deprecated - legacy field */
  age?: number;
  properties?: IRoomEvent;
}

export interface IStateEvent extends IRoomEvent {
  prev_content?: IContent;
  state_key: string;
}

interface IState {
  events: IStateEvent[];
}

export interface ITimeline {
  events: Array<IRoomEvent | IStateEvent>;
  limited?: boolean;
  prev_batch: string;
}

export interface IPublicRoomsChunkRoom {
  room_id: string;
  name?: string;
  avatar_url?: string;
  topic?: string;
  canonical_alias?: string;
  aliases?: string[];
  world_readable: boolean;
  guest_can_join: boolean;
  num_joined_members: number;
}

export interface ISend {
  roomId: string;
  eventType: EventType;
  msgtype: MsgType;
  txnId: number;
  body: string;
}

interface IRoomSummary
  extends Omit<IPublicRoomsChunkRoom, "canonical_alias" | "aliases"> {
  room_type?: RoomType;
  membership?: string;
  is_encrypted: boolean;
}

export interface IJoinedRoom {
  summary: IRoomSummary;
  state: IState;
  timeline: ITimeline;
  ephemeral: IEphemeral;
  account_data: IAccountData;
  unread_notifications: IUnreadNotificationCounts;
}

export interface IStrippedState {
  content: IContent;
  state_key: string;
  type: EventType | string;
  sender: string;
}

export interface IInviteState {
  events: IStrippedState[];
}

export interface IInvitedRoom {
  invite_state: IInviteState;
}

export interface ILeftRoom {
  state: IState;
  timeline: ITimeline;
  account_data: IAccountData;
}

export interface IRooms {
  [Category.Join]: Record<string, IJoinedRoom>;
  [Category.Invite]: Record<string, IInvitedRoom>;
  [Category.Leave]: Record<string, ILeftRoom>;
}

interface IPresence {
  events: IMinimalEvent[];
}

interface IAccountData {
  events: IMinimalEvent[];
}

interface IToDeviceEvent {
  content: IContent;
  sender: string;
  type: string;
}

interface IToDevice {
  events: IToDeviceEvent[];
}

interface IDeviceLists {
  changed: string[];
  left: string[];
}

export interface ISyncResponse {
  next_batch: string;
  rooms: IRooms;
  presence?: IPresence;
  account_data: IAccountData;
  to_device?: IToDevice;
  device_lists?: IDeviceLists;
  device_one_time_keys_count?: Record<string, number>;
}
/* eslint-enable camelcase */

export enum Category {
  Invite = "invite",
  Leave = "leave",
  Join = "join",
}

export enum ReceiptType {
  Read = "m.read",
  FullyRead = "m.fully_read",
  ReadPrivate = "org.matrix.msc2285.read.private",
}

// interface IRoom {
//   _currentState: { [eventType: string]: { [stateKey: string]: IStateEvent } };
//   _timeline: {
//     event: IRoomEvent | IStateEvent;
//     token: string | null;
//   }[];
//   _summary: Partial<IRoomSummary>;
//   _accountData: { [eventType: string]: IMinimalEvent };
//   _unreadNotifications: Partial<IUnreadNotificationCounts>;
//   _readReceipts: {
//     [userId: string]: {
//       data: IMinimalEvent;
//       type: ReceiptType;
//       eventId: string;
//     };
//   };
// }

export interface ISyncData {
  nextBatch: string;
  accountData: IMinimalEvent[];
  roomsData: IRooms;
}

/* eslint-disable */
export interface IFile {
  [key: string]: any;
  url: string;
}
/* eslint-enable */

/* eslint-disable */
export interface IInfo {
  [key: string]: any;
  mimetype?: string;
  size?: number;
}
/* eslint-enable */

/* eslint-disable */
export interface IContent {
  [key: string]: any;
  file?: IFile;
  info?: IInfo;
  msgtype?: MsgType | string;
  membership?: string;
  avatar_url?: string;
  displayname?: string;
  "m.relates_to"?: IEventRelation;
}
/* eslint-enable */

type StrippedState = Required<
  Pick<IEvent, "content" | "state_key" | "type" | "sender">
>;

/* eslint-disable */
export interface IUnsigned {
  age?: number;
  prev_sender?: string;
  prev_content?: IContent;
  redacted_because?: IEvent;
  transaction_id?: string;
  invite_room_state?: StrippedState[];
  "m.relations"?: Record<RelationType | string, any>; // No common pattern for aggregated relations
}
/* eslint-enable */

export interface IThreadBundledRelationship {
  latest_event: IEvent;
  count: number;
  current_user_participated?: boolean;
}

export interface IEvent {
  event_id: string;
  eventId?: string;
  type: string;
  content: IContent;
  sender: string;
  room_id: string;
  origin_server_ts: number;
  txn_id?: string;
  state_key?: string;
  membership?: string;
  unsigned: IUnsigned;
  redacts?: string;

  /**
   * @deprecated
   */
  user_id?: string;
  /**
   * @deprecated
   */
  prev_content?: IContent;
  /**
   * @deprecated
   */
  age?: number;
}

export interface IAggregatedRelation {
  origin_server_ts: number;
  event_id?: string;
  sender?: string;
  type?: string;
  count?: number;
  key?: string;
}

export interface IEventRelation {
  rel_type?: RelationType | string;
  event_id?: string;
  is_falling_back?: boolean;
  "m.in_reply_to"?: {
    event_id: string;
  };
  key?: string;
}

/**
 * When an event is a visibility change event, as per MSC3531,
 * the visibility change implied by the event.
 */
export interface IVisibilityChange {
  /**
   * If `true`, the target event should be made visible.
   * Otherwise, it should be hidden.
   */
  visible: boolean;

  /**
   * The event id affected.
   */
  eventId: string;

  /**
   * Optionally, a human-readable reason explaining why
   * the event was hidden. Ignored if the event was made
   * visible.
   */
  reason: string | null;
}

export interface IClearEvent {
  room_id?: string;
  type: string;
  content: Omit<
    IContent,
    "membership" | "avatar_url" | "displayname" | "m.relates_to"
  >;
  unsigned?: IUnsigned;
}
/* eslint-enable camelcase */

// interface IKeyRequestRecipient {
//   userId: string;
//   deviceId: "*" | string;
// }

export interface IDecryptOptions {
  emit?: boolean;
  isRetry?: boolean;
}

/**
 * Message hiding, as specified by https://github.com/matrix-org/matrix-doc/pull/3531.
 */
export type MessageVisibility =
  | IMessageVisibilityHidden
  | IMessageVisibilityVisible;
/**
 * Variant of `MessageVisibility` for the case in which the message should be displayed.
 */
export interface IMessageVisibilityVisible {
  readonly visible: true;
}
/**
 * Variant of `MessageVisibility` for the case in which the message should be hidden.
 */
export interface IMessageVisibilityHidden {
  readonly visible: false;
  /**
   * Optionally, a human-readable reason to show to the user indicating why the
   * message has been hidden (e.g. "Message Pending Moderation").
   */
  readonly reason: string | null;
}

export interface ISyncRequest {
  since?: string;
  timeout?: number;
  _cacheBuster?: number;
}
