import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nav low-menu" }
const _hoisted_2 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_3 = {
  key: 0,
  class: "badge rounded-pill bg-primary"
}
const _hoisted_4 = { class: "nav-item flex-last-left" }
const _hoisted_5 = {
  key: 0,
  class: "badge rounded-pill bg-primary"
}
const _hoisted_6 = { class: "md-up-visible" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_check_field = _resolveComponent("check-field")!
  const _component_search_field = _resolveComponent("search-field")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.isMemberVisible)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createVNode(_component_router_link, {
            "active-class": "active",
            class: "nav-link",
            "aria-current": "page-content",
            to: { name: 'OrganizationMembers' },
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateMembersDescendant(true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("organization.members.title")), 1),
              (
            !_ctx.isLoadingMembers &&
            _ctx.membersCount > 0 &&
            _ctx.isMembersRoute &&
            _ctx.membersDescendant === true
          )
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.membersCount), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_4, [
      _createVNode(_component_router_link, {
        "active-class": "active",
        class: "nav-link",
        "aria-current": "page-content",
        to: { name: 'OrganizationAdmins' },
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateAdminsDescendant(true)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("organization.admins.title")), 1),
          (
            !_ctx.isLoadingAdmins &&
            _ctx.adminsCount > 0 &&
            _ctx.isAdminsRoute &&
            _ctx.adminsDescendant === true
          )
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.adminsCount), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", _hoisted_6, [
      (_ctx.isMembersRoute)
        ? (_openBlock(), _createBlock(_component_check_field, {
            key: 0,
            state: _ctx.membersVerified,
            "onUpdate:state": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.membersVerified) = $event)),
            titleNoFilter: 
          _ctx.$t('organization.members.filters.verified.labels.no-filter')
        ,
            titleChecked: 
          _ctx.$t('organization.members.filters.verified.labels.checked')
        ,
            titleUnchecked: 
          _ctx.$t('organization.members.filters.verified.labels.unchecked')
        
          }, null, 8, ["state", "titleNoFilter", "titleChecked", "titleUnchecked"]))
        : _createCommentVNode("", true),
      (_ctx.isMembersRoute)
        ? (_openBlock(), _createBlock(_component_search_field, {
            key: 1,
            placeholder: _ctx.$t(`global.search`),
            search: _ctx.membersSearch,
            "onUpdate:search": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.membersSearch) = $event))
          }, null, 8, ["placeholder", "search"]))
        : _createCommentVNode("", true),
      (_ctx.isAdminsRoute)
        ? (_openBlock(), _createBlock(_component_search_field, {
            key: 2,
            placeholder: _ctx.$t(`global.search`),
            search: _ctx.adminsSearch,
            "onUpdate:search": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.adminsSearch) = $event))
          }, null, 8, ["placeholder", "search"]))
        : _createCommentVNode("", true)
    ])
  ]))
}