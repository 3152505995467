import { App } from "vue";
import * as Sentry from "@sentry/vue";
import { Router } from "vue-router";
import { getEnv } from "@/utils";

declare const APP_VERSION: string;

interface SentryProviderPluginOptions {
  DSN?: string;
  targets?: Array<string | RegExp>;
}

const defaultOptions: SentryProviderPluginOptions = {
  DSN: "",
  targets: [],
};

const env = getEnv("environment", "production");

export class SentryProviderPlugin {
  private options: SentryProviderPluginOptions;

  constructor(options?: SentryProviderPluginOptions) {
    this.options = { ...defaultOptions, ...(options || {}) };
  }

  async install(app: App, vueRouter: Router): Promise<void> {
    Sentry.init({
      app,
      dsn: this.options.DSN,
      // release: `${APP_VERSION}`,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(vueRouter),
          tracePropagationTargets: this.options.targets,
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      environment: env,
      tracesSampleRate: env === "production" ? 0.2 : 1.0,
      attachProps: true,
      maxBreadcrumbs: 10,
      attachStacktrace: true,
      // beforeSend(event, hint) {
      //   // Check if it is an exception, and if so, show the report dialog
      //   if (event.exception) {
      //     Sentry.showReportDialog({ eventId: event.event_id });
      //   }
      //   return event;
      // },
    });
    // Sentry.setTag("version", `${APP_VERSION}`);
  }
}

function createSentry(
  options: SentryProviderPluginOptions
): SentryProviderPlugin {
  return new SentryProviderPlugin(options);
}

export default createSentry({
  DSN: getEnv("sentryDsn"),
  targets: [window.location.host, /^\//],
});
