import { OrganizationUser, OrganizationUsersGroup } from "@/store/models";
import AbstractSubOrgRepository from "./abstractSubOrgRepository";

export default class OrgAdminsRepository extends AbstractSubOrgRepository<OrganizationUser> {
  protected groupId?: string;

  constructor() {
    super({
      subject: "OrganizationAdmin",
      type: "organizations",
      subType: "users",
      endpoint: "{id}/users/{groupId}",
      TypeConstructor: OrganizationUser,
    });
  }

  public setGroup(
    group?: OrganizationUsersGroup | string
  ): OrgAdminsRepository {
    this.groupId = typeof group === "string" ? group : group?.id || "";
    return this;
  }

  public save(model: OrganizationUser): Promise<OrganizationUser> {
    const oldSelectedGroup = this.groupId;
    this.groupId = undefined;
    return super.save(model).finally(() => {
      this.groupId = oldSelectedGroup;
    });
  }

  public delete(model: OrganizationUser): Promise<OrganizationUser> {
    const oldSelectedGroup = this.groupId;
    this.groupId = undefined;
    return super.delete(model).finally(() => {
      this.groupId = oldSelectedGroup;
    });
  }
}
