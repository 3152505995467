import { AbstractModel } from ".";

export default class OrganizationUser extends AbstractModel {
  type = "OrganizationUser";
  id?: string;
  email?: string;
  adminGroup?: string | null;
  firstName?: string;
  lastName?: string;
}
