import { Vue } from "vue-class-component";

export type ToastContentType = "text" | "progress";
export type ToastEventLevel = "info" | "warning" | "error" | "success";
export interface ToastContent {
  readonly type: ToastContentType;
  readonly data: Record<string, unknown> | undefined;
}

export class ToastMixin extends Vue {
  addSuccessEvent(title: string, content?: string): ToastEvent {
    return this.addTextEvent("success", title, content);
  }

  addErrorEvent(title: string, content?: string): ToastEvent {
    return this.addTextEvent("error", title, content);
  }

  addTextEvent(
    level: ToastEventLevel,
    title: string,
    text?: string
  ): ToastEvent {
    if (text) {
      return this.addEvent(level, title, { type: "text", data: { text } });
    }
    return this.addEvent(level, title);
  }

  addEvent(
    level: ToastEventLevel,
    title: string,
    content?: ToastContent
  ): ToastEvent {
    const event = new ToastEvent().setLevel(level).setTitle(title);
    if (content) {
      event.setContent(content);
    }
    return this.addEventObject(event);
  }

  addEventObject(event: ToastEvent): ToastEvent {
    this.$store.commit("events/add", event);
    return event;
  }

  removeEvent(event: ToastEvent): void {
    this.$store.commit("events/remove", event);
  }
}

export default class ToastEvent {
  readonly id: string;
  readonly creationDate: Date;
  title?: string;
  level: ToastEventLevel = "info";
  content?: ToastContent;
  toTranslate = false;

  constructor() {
    this.creationDate = new Date();
    this.id = "_" + Math.random().toString(36).substr(2, 9);
  }

  setTitle(title: string): ToastEvent {
    this.title = title;
    return this;
  }

  setLevel(level: ToastEventLevel): ToastEvent {
    this.level = level;
    return this;
  }

  setContent(content: ToastContent): ToastEvent {
    this.content = content;
    return this;
  }

  setToTranslate(): ToastEvent {
    this.toTranslate = true;
    return this;
  }

  clone(): ToastEvent {
    return { ...this };
  }
}
