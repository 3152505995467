import { PublicationReaction } from "@/store/models";
import AbstractSubPublicationsRepository from "./abstractSubPublicationRepository";

export default class PublicationReactionsRepository extends AbstractSubPublicationsRepository<PublicationReaction> {
  constructor() {
    super({
      subject: "TribeInformationPublications",
      endpoint: "{id}/reactions",
      type: "publications",
      subType: "reactions",
      TypeConstructor: PublicationReaction,
    });
  }
}
