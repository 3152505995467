import { Model, TribeInformation } from "@/store/models";
import { AbstractRepository, Repository } from "./abstractRepository";

export interface SubTribeRepository<M extends Model> extends Repository<M> {
  setTribe(tribe: TribeInformation | string): SubTribeRepository<M>;
}

export default abstract class AbstractSubTribeRepository<M extends Model>
  extends AbstractRepository<M>
  implements SubTribeRepository<M>
{
  protected id!: string | undefined;

  public setTribe(tribe: TribeInformation | string): SubTribeRepository<M> {
    this.id = typeof tribe === "string" ? tribe : tribe.id;
    return this;
  }
}
