
import { ToastEvent } from "@/store/models";
import { Vue, Options } from "vue-class-component";
import Toast from "./Toast.vue";

@Options({
  components: {
    Toast,
  },
})
export default class ToastsContainer extends Vue {
  get events(): Record<string, ToastEvent> {
    return this.$store.state.events?.list || {};
  }
}
