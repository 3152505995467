import "./bootstrap";
import "./dayjs";
import i18nPlugin from "./i18n";
import i18nCountriesPlugin from "./i18nCountries";
import oidcPlugin from "./oidc";
import createAbilities from "./casl";
import createBreakpoints from "./breakpoints";
import createFeatures from "./features";
import createMobileAppVersion from "./mobileAppVersion";
import createChat from "./chat";
import createSentry from "./sentry";

export const i18n = i18nPlugin;
export const i18nCountries = i18nCountriesPlugin;
export const oidc = oidcPlugin;
export const casl = createAbilities;
export const breakpoints = createBreakpoints;
export const features = createFeatures;
export const mobileAppVersion = createMobileAppVersion;
export const chat = createChat;
export const sentry = createSentry;

export default {};
