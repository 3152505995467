import { ChatRoom } from "../models";
import { IMinimalEvent, IRoomEvent, IStateEvent } from "../types";

export enum ParsingWay {
  Forward,
  Backward,
}

export default interface Parser {
  room: ChatRoom;
  parse(
    event: IRoomEvent | IStateEvent | IMinimalEvent,
    parsingWay?: ParsingWay
  ): Promise<Parser>;
}
